import * as React from 'react';

function SvgTarget1(props) {
  return (
    <svg viewBox="0 0 123 123" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.788 6.63a.861.861 0 01.353-.326A61.778 61.778 0 0165.388.134a.86.86 0 01.8.912.86.86 0 01-.912.791 60.084 60.084 0 00-30.377 6.024.86.86 0 01-1.145-.396.86.86 0 01.034-.834zm44.156-4.156a.86.86 0 00-.423.353 1.043 1.043 0 00-.069.224.86.86 0 00.577 1.067 59.455 59.455 0 0114.216 6.265 59.951 59.951 0 0111.79 9.336.857.857 0 001.213-1.213 61.658 61.658 0 00-12.125-9.612 62.01 62.01 0 00-14.63-6.446.86.86 0 00-.55.026zM49.27 12.58a50.418 50.418 0 00-31.074 22.793 50.42 50.42 0 0016.978 69.17 50.418 50.418 0 0069.17-16.979 50.419 50.419 0 00-16.978-69.17A50.42 50.42 0 0049.27 12.58zm59.223 37.29a48.697 48.697 0 01-5.612 36.8 48.707 48.707 0 01-66.82 16.41 48.717 48.717 0 01-16.402-66.821A48.698 48.698 0 0186.47 19.857a48.695 48.695 0 0122.022 30.012zM21.931 14.22A61.436 61.436 0 008.696 29.624a61.607 61.607 0 00-7.35 18.08.86.86 0 00.646 1.024.86.86 0 001.024-.637 59.832 59.832 0 017.151-17.58 59.625 59.625 0 0112.856-14.974.86.86 0 00.112-1.205.86.86 0 00-1.204-.112zM.486 69.226a.86.86 0 01.62-.413.86.86 0 01.963.74 59.85 59.85 0 005.413 17.942.86.86 0 01-.404 1.145.86.86 0 01-1.145-.405 61.76 61.76 0 01-5.567-18.45.86.86 0 01.12-.559zm13.218 29.835a.86.86 0 00-.12 1.205 61.214 61.214 0 0015.842 13.768 60.856 60.856 0 0048.25 6.661.861.861 0 00.603-1.05.859.859 0 00-1.059-.594 59.202 59.202 0 01-46.908-6.48A59.8 59.8 0 0114.908 99.19a.86.86 0 00-1.204-.13zm76.493 14.827a.866.866 0 01.318-.31 59.75 59.75 0 0021.858-21.152 59.311 59.311 0 008.158-23.234.863.863 0 01.964-.74.86.86 0 01.74.963 61.032 61.032 0 01-8.399 23.915 61.466 61.466 0 01-22.486 21.763.863.863 0 01-1.282-.768.86.86 0 01.13-.437zm25.807-78.472a.85.85 0 00-.498.73.858.858 0 00.077.405 60.433 60.433 0 013.803 11.067.863.863 0 001.033.628.86.86 0 00.637-1.032 61.964 61.964 0 00-3.915-11.377.863.863 0 00-1.137-.422z"
        fill="url(#target-1_svg__paint0_linear_224_8)"
      />
      <defs>
        <linearGradient
          id="target-1_svg__paint0_linear_224_8"
          x1={86.677}
          y1={27.533}
          x2={38.918}
          y2={116.169}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD00CE" />
          <stop offset={0.5} stopColor="#FF4937" />
          <stop offset={0.995} stopColor="#FFBE38" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTarget1;
