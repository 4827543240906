import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';
import { fadeInAndOutAndAnimateHeightVariants, pfizer } from 'styles';

export default ({
  errorInitialState = { code: null, message: null },
  errorMessage,
  setError,
  style,
  timeout,
  variants = fadeInAndOutAndAnimateHeightVariants()
}) => {
  useEffect(() => {
    if (timeout) {
      let timer;
      if (errorMessage) {
        timer = setTimeout(() => setError(errorInitialState), timeout);
      }
      return () => timer && clearTimeout(timer);
    }
  }, [errorMessage]);

  return (
    <AnimatePresence exitBeforeEnter>
      {errorMessage && (
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          style={{
            color: '#F8981D',
            textAlign: 'center',
            ...style
          }}
          variants={variants}>
          <Markup content={errorMessage} noWrap />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
