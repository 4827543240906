import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
// AccessibilityBar
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { breakpoints } from 'styles';
import { scrollToAnchor } from 'utils';
// import Eye from 'assets/svgs/eye-icon.svg';
import { motion } from 'framer-motion';

const EventNavbar = ({ eventName, eid, slug, eventSeries, eventAgenda, colors }) => {
  const { theme, mobile, scrollTo, headerRef, agendaRef, contactRef } = useContext(LocalContext);
  const { pathname } = useLocation();
  const { user } = useContext(FirebaseContext);
  // const [themeToggleOpen, setThemeToggleOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);
  // let isMounted = true;

  // useEffect(() => {
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  useEffect(() => setActiveLink(pathname), [pathname]);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const toggleActiveLink = (link) => {
    setActiveLink(link);
  };

  return (
    <Wrapper
      colors={colors}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      scrolled={isScrolled}
      // themeToggle={themeToggleOpen}
      theme={theme}>
      <EventNameContainer
        to={`/${slug}/`}
        aria-label="Home"
        onClick={(e) => {
          // toggleActiveLink('welcome');
          e.preventDefault();
        }}>
        <h3>{eventName}</h3>
      </EventNameContainer>
      <NavLinks
        initial={mobile ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.75 }}>
        <NavLink
          colors={colors}
          to={`/events/${slug}/livestream`}
          className={activeLink === '/livestream' ? 'active' : null}
          onClick={(e) => {
            e.preventDefault();
            toggleActiveLink('/livestream');
            scrollToAnchor('livestream', {
              duration: 1200
            });
          }}>
          Livestream
        </NavLink>
        <NavLink
          colors={colors}
          to="/"
          className={activeLink === '/info' ? 'active' : null}
          onClick={(e) => {
            e.preventDefault();
            toggleActiveLink('/info');
            scrollToAnchor('info', {
              duration: 1200,
              offset: -79
            });
          }}>
          Info
        </NavLink>
        <NavLink
          colors={colors}
          to="/"
          className={activeLink === '/agenda' ? 'active' : null}
          onClick={(e) => {
            e.preventDefault();
            toggleActiveLink('/agenda');
            scrollToAnchor('agenda', {
              duration: 1200,
              offset: -190
            });
          }}>
          Agenda
        </NavLink>
      </NavLinks>
    </Wrapper>
  );
};

const NavLink = styled(Link)`
  align-items: center;
  color: white-space;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 3rem;
  margin-left: 5rem;
  padding-top: 0.125rem;
  text-align: center;
  transition: 0.5s;
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: white;
    width: auto;
    border-bottom: 2px solid transparent;
  }
  &:hover {
    border-bottom: 2px solid white;
  }
  &.active {
    border-bottom: 2px solid transparent;
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  background: white;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  grid-area: links;
  grid-gap: 0px;
  height: auto;
  justify-content: center;
  @media (min-width: ${breakpoints.lg}) {
    grid-column: 6/12;
    background: transparent;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    justify-content: flex-end;
    height: 100%;
    margin-top: 0px;
    box-shadow: none;
  }
`;

const EventNameContainer = styled(Link)`
  grid-area: logo;
  height: auto;
  h3 {
    color: white;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1em;
    margin: 0;
    text-align: left;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-column: 2/8;
    height: auto;
  }
`;

const Wrapper = styled(motion.nav)`
  background: ${({ colors }) => colors.primary};
  background: 'white';
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  box-shadow: none;
  display: none;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(12, 1fr);
  height: 3.5rem;
  position: fixed;
  top: 4.8rem;
  transition-duration: 0.5s;
  width: 100%;
  z-index: 3;

  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-areas: 'logo links';
    align-items: center;
  }
`;

// const ThemeToggle = styled.div`
//   cursor: pointer;
//   position: relative;
//   width: 3.75rem;
//   height: 3.75rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   grid-area: eye;
//   justify-self: end;
//   background: ${({ open, theme }) => (open ? theme.bgLight : 'transparent')};
//   svg {
//     path {
//       stroke: ${({ theme }) => theme.textColor};
//       :last-child {
//         fill: ${({ theme }) => theme.textColor};
//       }
//     }
//   }
//   @media (min-width: ${breakpoints.lg}) {
//     grid-column: 12/13;
//     justify-self: start;
//     width: 4rem;
//     height: 4rem;
//   }
// `;

export default EventNavbar;
