import { format } from 'date-fns';
import firebase from 'firebase/app';
import firebaseConfig from './config';

let firebaseInstance;

class Firebase {
  constructor(app) {
    if (!firebaseInstance) {
      app.initializeApp(firebaseConfig);
      this.auth = app.auth();
      this.rtdb = app.database();
      this.fsdb = app.firestore();
      this.storage = app.storage;
      this.functions = firebase.app().functions('europe-west2');
      // if (window.location.hostname.includes('localhost')) {
      //   this.auth.useEmulator('http://localhost:9099');
      //   this.fsdb.useEmulator('localhost', 8080);
      //   this.functions.useEmulator('localhost', 5001);
      // }
    }
  }

  async createUserInDatabase({ newUserLocalStorageData, referringUrl, uid }) {
    const batch = this.fsdb.batch();

    const userRef = this.fsdb.collection('users').doc(uid);
    const userEmailsRef = this.fsdb.collection('userEmails').doc(uid);

    const timestamp = Date.now();

    batch.set(userRef, {
      name: newUserLocalStorageData.name,
      email: newUserLocalStorageData.email.toLowerCase(),
      region: newUserLocalStorageData.region,
      country: newUserLocalStorageData.country,
      function: newUserLocalStorageData.function,
      day2Franchise: newUserLocalStorageData.day2Franchise,
      day3Franchise: newUserLocalStorageData.day3Franchise,
      uid,
      referringUrl,
      avatarUrl: '',
      registeredAt: {
        timestamp,
        formatted: format(timestamp, 'dd/MM/yy - HH:mm')
      }
    });

    batch.set(userEmailsRef, {
      email: newUserLocalStorageData.email.toLowerCase(),
      uid
    });

    return batch.commit();
  }

  async logout() {
    return this.auth.signOut();
  }

  async fetchAllUsers() {
    const fetchAllUsersCallable = this.functions.httpsCallable('fetchAllUsers');
    return fetchAllUsersCallable();
  }

  async getUserFromDatabaseWithUID({ uid }) {
    return this.fsdb.collection('users').where('uid', '==', uid).limit(1).get();
  }

  async checkIfUserAlreadyExists({ email }) {
    const checkIfUserAlreadyExistsCallable = this.functions.httpsCallable(
      'checkIfUserAlreadyExists'
    );
    return checkIfUserAlreadyExistsCallable({ email });
  }

  async sendEmoji({ did, eid, emojiType }) {
    return this.rtdb
      .ref(`/emojis/${did}_${eid}`)
      .push()
      .set({ emojiType, timestamp: firebase.database.ServerValue.TIMESTAMP });
  }

  async saveNewPoll({ did, eid, poll }) {
    const pid = this.rtdb.ref(`polls/${did}_${eid}`).push().key;

    const answers = {};

    poll.answers.forEach((answer) => {
      answers[`${answer.id}`] = 0;
    });

    return this.rtdb.ref().update({
      [`/polls/${did}_${eid}/${pid}`]: poll,
      [`/pollAnalytics/${did}_${eid}/${pid}`]: {
        pollType: poll.type,
        question: poll.question.text,
        correctAnswers: poll.answers.some((answer) => answer.isCorrect)
          ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
          : [],
        answers,
        totalParticipants: 0
      }
    });
  }

  async editPoll({ did, eid, poll, pid }) {
    const answers = {};

    poll.answers.forEach((answer) => {
      answers[`${answer.id}`] = 0;
    });

    return this.rtdb.ref().update({
      [`/polls/${did}_${eid}/${pid}`]: poll,
      [`/pollAnalytics/${did}_${eid}/${pid}`]: {
        pollType: poll.type,
        question: poll.question.text,
        correctAnswers: poll.answers.some((answer) => answer.isCorrect)
          ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
          : [],
        answers,
        totalParticipants: 0
      }
    });
  }

  async deletePoll({ did, eid, pid }) {
    this.rtdb.ref().update({
      [`/polls/${did}_${eid}/${pid}`]: null,
      [`/pollAnalytics/${did}_${eid}/${pid}`]: null
    });
  }

  async submitPollAnswer({ did, eid, poll, uid, selectedAnswerIds }) {
    const { pid } = poll;

    const answers = {};

    selectedAnswerIds.forEach((aid) => {
      answers[aid] = firebase.database.ServerValue.increment(1);
    });

    /* TODO: Finish the fsdb query */
    /* Make each answer an object, with answer.id and answer.correct on it */
    return Promise.all([
      this.rtdb.ref(`/pollAnalytics/${did}_${eid}/${pid}/answers`).update(answers),
      this.rtdb.ref(`/pollAnalytics/${did}_${eid}/${pid}`).update({
        totalParticipants: firebase.database.ServerValue.increment(1)
      }),
      this.fsdb
        .collection('users')
        .doc(uid)
        .collection('pollAnalytics')
        .doc(`${did}_${eid}_${pid}`)
        .set({
          uid,
          answers: selectedAnswerIds
        })
    ]);
  }

  async checkIfUserHasAlreadyAnsweredThisPoll({ did, eid, pid, uid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('pollAnalytics')
      .doc(`${did}_${eid}_${pid}`)
      .get();
  }

  async generateUserReport({ email, selectedEvent }) {
    const generateUserReportCallable = this.functions.httpsCallable('generateUserReport');
    return generateUserReportCallable({ email: email.toLowerCase(), selectedEvent });
  }

  async generateLoggedInOnTheDayReport({ users }) {
    const generateLoggedInOnTheDayReportCallable = this.functions.httpsCallable(
      'generateLoggedInOnTheDayReport'
    );
    return generateLoggedInOnTheDayReportCallable({ users });
  }

  async generateEventCommentsReport({ selectedEvent }) {
    const generateEventCommentsReportCallable = this.functions.httpsCallable(
      'generateEventCommentsReport'
    );
    return generateEventCommentsReportCallable({ selectedEvent });
  }

  async sendSignInWithMagicLinkEmail({ firstName, email, colors, actionCodeSettings }) {
    const sendSignInWithEmailLinkEmailCallable = this.functions.httpsCallable(
      'sendSignInWithMagicLinkEmail'
    );
    return sendSignInWithEmailLinkEmailCallable({
      firstName,
      email: email.toLowerCase(),
      actionCodeSettings,
      colors
    });
  }

  async updateNameInDatabase({ uid, name }) {
    return this.fsdb.collection('users').doc(uid).update({
      name
    });
  }

  async updateEmailInDatabase({ uid, email }) {
    const { fsdb } = this;

    const usersRef = fsdb.collection('users').doc(uid);
    const userEmailsRef = fsdb.collection('userEmails').doc(uid);

    const batch = fsdb.batch();

    batch.update(usersRef, {
      email: email.toLowerCase()
    });

    batch.update(userEmailsRef, {
      email: email.toLowerCase()
    });

    return batch.commit();
  }

  async grantUserAdminPermissions({ uid }) {
    const grantUserAdminPermissionsCallable = this.functions.httpsCallable(
      'grantUserAdminPermissions'
    );
    return grantUserAdminPermissionsCallable({ uid });
  }

  async grantUserModeratorPermissions({ uid, dayAndEventId }) {
    const grantUserModeratorPermissionsCallable = this.functions.httpsCallable(
      'grantUserModeratorPermissions'
    );
    return grantUserModeratorPermissionsCallable({ uid, dayAndEventId });
  }

  async removeUserModeratorPermissions({ uid }) {
    const removeUserModeratorPermissionsCallable = this.functions.httpsCallable(
      'removeUserModeratorPermissions'
    );
    return removeUserModeratorPermissionsCallable({ uid });
  }

  async applyActionCode(actionCode) {
    return this.auth.applyActionCode(actionCode);
  }

  async checkActionCode(actionCode) {
    return this.auth.checkActionCode(actionCode);
  }

  async uploadAvatarToDatabase(avatarFile) {
    const uploadAvatarToDatabaseCallable = this.functions.httpsCallable('uploadAvatarToDatabase');
    return uploadAvatarToDatabaseCallable({ avatarFile });
  }

  async sendReminderEmail() {
    const sendReminderEmailCallable = this.functions.httpsCallable('sendReminderEmail');
    return sendReminderEmailCallable();
  }

  async fetchUser({ uid }) {
    return this.fsdb.collection('users').doc(uid).get();
  }

  async fetchAllParticipants(lastFetchedParticipantDoc) {
    let query;

    if (lastFetchedParticipantDoc) {
      query = this.fsdb
        .collection('users')
        .orderBy('name')
        .startAfter(lastFetchedParticipantDoc)
        .limit(20);
    } else {
      query = this.fsdb.collection('users').orderBy('name').limit(20);
    }

    return query.get();
  }

  async fetchDay2FranchiseParticipants({ dbEventTitle, lastFetchedParticipantDoc }) {
    let query;

    if (lastFetchedParticipantDoc) {
      query = this.fsdb
        .collection('users')
        .where('day2Franchise', '==', dbEventTitle)
        .orderBy('name')
        .startAfter(lastFetchedParticipantDoc)
        .limit(20);
    } else {
      query = this.fsdb
        .collection('users')
        .where('day2Franchise', '==', dbEventTitle)
        .orderBy('name')
        .limit(20);
    }

    return query.get();
  }

  async fetchDay3FranchiseParticipants({ dbEventTitle, lastFetchedParticipantDoc }) {
    let query;

    if (lastFetchedParticipantDoc) {
      query = this.fsdb
        .collection('users')
        .where('day3Franchise', '==', dbEventTitle)
        .orderBy('name')
        .startAfter(lastFetchedParticipantDoc)
        .limit(20);
    } else {
      query = this.fsdb
        .collection('users')
        .where('day3Franchise', '==', dbEventTitle)
        .orderBy('name')
        .limit(20);
    }

    return query.get();
  }

  async postEventComment({
    avatarUrl,
    country,
    did,
    eid,
    function: _function,
    name,
    region,
    text,
    uid
  }) {
    return this.fsdb
      .collection('events')
      .doc(`${did}_${eid}`)
      .collection('comments')
      .add({
        avatarUrl,
        country,
        function: _function,
        name,
        region,
        text,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async deleteEventComment({ did, eid, cid }) {
    return this.fsdb
      .collection('events')
      .doc(`${did}_${eid}`)
      .collection('comments')
      .doc(cid)
      .delete();
  }

  async pinEventComment({ did, eid, cid }) {
    return this.fsdb
      .collection('events')
      .doc(`${did}_${eid}`)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async unpinEventComment({ did, eid, cid }) {
    return this.fsdb
      .collection('events')
      .doc(`${did}_${eid}`)
      .collection('comments')
      .doc(cid)
      .update({
        pinned: {
          status: false,
          timestamp: 0
        }
      });
  }

  async starQuestion({ uid, qid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        starred: {
          status: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async unstarQuestion({ uid, qid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        starred: {
          status: false,
          timestamp: 0
        }
      });
  }

  async sendWelcomeEmail({ name, email, day2Franchise, day3Franchise, colors }) {
    const sendEventWelcomeEmailCallable = this.functions.httpsCallable('sendWelcomeEmail');
    return sendEventWelcomeEmailCallable({
      name,
      email: email.toLowerCase(),
      day2Franchise,
      day3Franchise,
      colors
    });
  }

  async submitNewQuestion({ uid, did, eid, text, name }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(`${uid}_${Date.now()}`)
      .set(
        name
          ? {
              did: did.toString(),
              eid: eid.toString(),
              text,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              uid,
              name,
              starred: {
                status: false,
                timestamp: 0
              }
            }
          : {
              did: did.toString(),
              eid: eid.toString(),
              text,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              uid,
              starred: {
                status: false,
                timestamp: 0
              }
            }
      );
  }

  async answerThisQuestionLive({ did, eid, text }) {
    return this.fsdb.collection('events').doc(`${did}_${eid}`).update({
      questionCurrentlyBeingAnsweredLive: text
    });
  }

  async stopShowingAnswerLiveOverlay({ did, eid }) {
    return this.fsdb.collection('events').doc(`${did}_${eid}`).update({
      questionCurrentlyBeingAnsweredLive: null
    });
  }

  async submitAnswer({ text, qid, uid }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .doc(qid)
      .update({
        answer: {
          text,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      });
  }

  async updateVideoSessionData({ did, eid, uid, timeRange }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('videoSessionData')
      .doc(`${did}_${eid}`)
      .set(
        {
          timeRanges: firebase.firestore.FieldValue.arrayUnion(...timeRange)
        },
        { merge: true }
      );
  }

  async openPoll({ did, eid, selectedPoll, currentlyOpenPoll }) {
    if (currentlyOpenPoll?.pid) {
      const pollsRef = this.rtdb.ref(`polls/${did}_${eid}`);

      if (selectedPoll.timer?.enabled) {
        return pollsRef.update({
          [`${currentlyOpenPoll?.pid}/isOpen`]: false,
          [`${selectedPoll.pid}/isOpen`]: true,
          [`${selectedPoll.pid}/timer`]: {
            ...selectedPoll.timer,
            startedAt: firebase.database.ServerValue.TIMESTAMP
          }
        });
      }

      return pollsRef.update({
        [`${currentlyOpenPoll?.pid}/isOpen`]: false,
        [`${selectedPoll.pid}/isOpen`]: true
      });
    }

    if (selectedPoll.timer?.enabled) {
      return this.rtdb.ref(`polls/${did}_${eid}/${selectedPoll.pid}`).update({
        isOpen: true,
        timer: {
          ...selectedPoll.timer,
          startedAt: firebase.database.ServerValue.TIMESTAMP
        }
      });
    }

    return this.rtdb.ref(`polls/${did}_${eid}/${selectedPoll.pid}`).update({ isOpen: true });
  }

  async closePoll({ did, eid, pid }) {
    return this.rtdb.ref(`polls/${did}_${eid}/${pid}`).update({ isOpen: false, isQueued: false });
  }

  async sharePollAnalytics({ did, eid, pid }) {
    return this.rtdb.ref(`polls/${did}_${eid}/${pid}`).update({ shareAnalytics: true });
  }

  async stopSharingPollAnalytics({ did, eid, pid }) {
    return this.rtdb.ref(`polls/${did}_${eid}/${pid}`).update({ shareAnalytics: false });
  }

  async forceActiveTab({ did, eid, tabName }) {
    return this.fsdb.collection('events').doc(`${did}_${eid}`).update({
      forcedActiveTab: tabName
    });
  }

  subscribeToUserUpdates({ uid, onSnapshot }) {
    return this.fsdb.collection('users').doc(uid).onSnapshot(onSnapshot);
  }

  subscribeToEventUpdates({ did, eid, onSnapshot }) {
    return this.fsdb.collection('events').doc(`${did}_${eid}`).onSnapshot(onSnapshot);
  }

  subscribeToEventComments({ did, eid, onSnapshot }) {
    return this.fsdb
      .collection('events')
      .doc(`${did}_${eid}`)
      .collection('comments')
      .orderBy('pinned.status', 'desc')
      .orderBy('pinned.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .limit(100)
      .onSnapshot(onSnapshot);
  }

  subscribeToProjectComments({ did, eid, projectDocument, onSnapshot }) {
    return this.fsdb
      .collection('events')
      .doc(`${did}_${eid}`)
      .collection('projects')
      .doc(projectDocument)
      .collection('comments')
      .orderBy('pinned.status', 'desc')
      .orderBy('pinned.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeToServerTimeOffset({ onSnapshot }) {
    const serverTimeOffsetRef = this.rtdb.ref('.info/serverTimeOffset');

    serverTimeOffsetRef.on('value', onSnapshot);

    return serverTimeOffsetRef;
  }

  subscribeToEmojis({ did, eid, onSnapshot }) {
    const emojisRef = this.rtdb.ref(`/emojis/${did}_${eid}`);

    emojisRef.orderByChild('timestamp').startAfter(Date.now()).on('child_added', onSnapshot);

    return emojisRef;
  }

  subscribeModeratorToAllSubmittedQuestions({ did, eid, onSnapshot }) {
    return this.fsdb
      .collectionGroup('questions')
      .where('did', '==', did)
      .where('eid', '==', eid)
      .orderBy('starred.status', 'desc')
      .orderBy('starred.timestamp', 'asc')
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeUserToTheirSubmittedQuestions({ did, eid, uid, onSnapshot }) {
    return this.fsdb
      .collection('users')
      .doc(uid)
      .collection('questions')
      .where('did', '==', did)
      .where('eid', '==', eid)
      .where('uid', '==', uid)
      .orderBy('timestamp', 'desc')
      .onSnapshot(onSnapshot);
  }

  subscribeToPolls({ did, eid, onSnapshot }) {
    const pollsRef = this.rtdb.ref(`polls/${did}_${eid}`);

    pollsRef.on('value', onSnapshot);

    return pollsRef;
  }

  subscribeToPollAnalytics({ did, eid, pid, onSnapshot }) {
    const pollAnalyticsRef = this.rtdb.ref(`/pollAnalytics/${did}_${eid}/${pid}`);

    pollAnalyticsRef.on('value', onSnapshot);

    return pollAnalyticsRef;
  }
}

function getFirebaseInstance(app) {
  if (!firebaseInstance && app) {
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }

  if (firebaseInstance) {
    return firebaseInstance;
  }

  return null;
}

export default getFirebaseInstance;
