import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, gridContainer } from 'styles';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { BgMoon1, BgMoon2, FbIcon, EmailIcon, LinkedinIcon, TwitterIcon } from 'assets/svgs';
import { Markup } from 'interweave';

export default ({ data, colors }) => {
  const speakerImage = getImage(data.image);
  const { mobile, theme } = useContext(LocalContext);
  return (
    <Slide colors={colors}>
      <SpeakerImgContainer>
        <SVG colors={colors}>
          <Circle1 />
        </SVG>
        <SVG colors={colors}>
          <Circle2 />
        </SVG>
        <SpeakerImg image={speakerImage} cropped />
      </SpeakerImgContainer>
      <SpeakerDetails>
        <SpeakerName>{data.name}</SpeakerName>
        {data.position && (
          <SpeakerTitle>
            <Markup content={data.position} />
          </SpeakerTitle>
        )}
        {data.fbLink !== '/' && (
          <SpeakerSocials>
            <SpeakerSocial href={data.fbLink} colors={colors}>
              <FbIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.linkedinLink} colors={colors}>
              <LinkedinIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.twLink} colors={colors}>
              <TwitterIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.emailLink} colors={colors}>
              <EmailIcon />
            </SpeakerSocial>
          </SpeakerSocials>
        )}

        <SpeakerSummary colors={colors}>
          <Markup content={data.summary} />
        </SpeakerSummary>
      </SpeakerDetails>
    </Slide>
  );
};

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

const SpeakerSocial = styled.a`
  margin-right: 0.625rem;
  svg {
    circle {
      fill: #00004e;
      transition: 0.5s;
    }
    path {
      fill: rgb(245, 250, 250);
      transition: 0.5s;
    }
  }
  :last-child {
    svg {
      path {
        fill: none;
        stroke: rgb(245, 250, 250);
        transition: 0.5s;
      }
    }
  }
  :hover {
    svg {
      circle {
        fill: ${({ colors }) => colors.tertiary};
      }
      path {
        fill: rgb(196, 196, 196);
      }
    }
    :last-child {
      svg {
        path {
          fill: none;
          stroke: rgb(196, 196, 196);
          transition: 0.5s;
        }
      }
    }
  }
`;

const SpeakerSummary = styled.p`
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 1.15rem !important;
  max-width: 400px !important;
  text-align: center !important;
  @media (min-width: 1150px) {
    text-align: justify !important;
    max-width: 100% !important;
  }
`;

const SpeakerName = styled.h3`
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  margin: 0 auto;
  text-align: center !important;
  @media (min-width: 1150px) {
    text-align: left !important;
    margin-left: 0;
  }
`;

const SpeakerTitle = styled.p`
  color: #00004e !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem !important;
  margin: 1rem 0 0.375rem !important;
  text-align: center !important;
  @media (min-width: 1150px) {
    margin: 0.25rem 0 0.375rem !important;
    text-align: left !important;
  }
`;

const SpeakerDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: center;
  margin-left: 0px;
  @media (min-width: ${breakpoints.lg}) {
    margin-left: -100px;
    padding: 2.5rem;
    grid-column: 5/11;
    align-items: flex-start;
  }
`;

const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  svg {
    path {
      fill: ${({ colors }) => colors.tertiary};
      transition: 0.5s;
    }
  }
  :nth-child(1) {
    svg {
      path {
        fill: ${({ colors }) => colors.tertiary};
      }
    }
  }
  :nth-child(2) {
    svg {
      path {
        fill: ${({ colors }) => colors.tertiary};
      }
    }
  }
`;

const Circle1 = styled(BgMoon1)`
  bottom: -15%;
  height: 100%;
  left: -15%;
  position: absolute;
  transition-duration: 1s;
  width: 100%;
`;
const Circle2 = styled(BgMoon2)`
  height: 100%;
  position: absolute;
  right: -15%;
  top: -15%;
  transition-duration: 1s;
  width: 100%;
`;

const SpeakerImg = styled(GatsbyImage)`
  border-radius: ${({ cropped }) => (cropped ? '50%' : '0px')};
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: 250px;
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 250px;
  z-index: 2;
`;

const SpeakerImgContainer = styled.div`
  grid-column: 1/7;
  justify-self: center;
  margin: 3rem auto;
  max-width: 100%;
  position: relative;
  width: auto;
  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    max-width: 100%;
    grid-column: 1/5;
    align-self: flex-start;
  }
`;

const Slide = styled.div`
  ${gridContainer}
  align-items: center;
  color: #00004e;
  height: auto;
  max-width: 100%;
  padding: 1.25rem;
  position: relative;
  width: 1200px;
`;
