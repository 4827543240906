import React from 'react';
import { Button } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AppleStoreLogo, GooglePlayLogo, QrCode } from 'assets/svgs';

const PlayingForPatientImpact = ({ style }) => {
  const { patientImpactImg } = useStaticQuery(graphql`
    query {
      patientImpactImg: file(relativePath: { eq: "assets/images/patient-impact.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 518, height: 420)
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  return (
    <Wrapper style={style}>
      <div>
        <h2>Playing For Patient Impact</h2>
        <p>
          Today we will be playing for Patient Impact. The iTransform World Challenge has been
          designed to help us raise money for some of our greatest patient charities, have fun,
          embrace technology, and get to know your team a little more!
          <br />
          <br />
          DOWNLOAD THE APP <strong>Mobile Adventures</strong> directly from{' '}
          <a
            href="https://apps.apple.com/us/app/mobile-adventures/id824257097"
            target="_blank"
            rel="noreferrer">
            App Store
          </a>{' '}
          and{' '}
          <a
            href="https://play.google.com/store/apps/details?id=uk.co.huntthegoose.app&hl=en_GB&gl=US"
            target="_blank"
            rel="noreferrer">
            Google Play
          </a>{' '}
          or <strong>scan the QR Code</strong>!
          <br />
          <br />
          Once you downloaded the app, wait to click on the Zoom link provided at game time!!
        </p>
        {windowWidth < 1100 ? (
          <StyledQrCode />
        ) : (
          <PatientImpactImage
            image={getImage(patientImpactImg)}
            alt="Person holding earth in hands"
          />
        )}
        <Logos>
          <a
            href="https://apps.apple.com/us/app/mobile-adventures/id824257097"
            target="_blank"
            rel="noreferrer">
            <AppleStoreLogo />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=uk.co.huntthegoose.app&hl=en_GB&gl=US"
            target="_blank"
            rel="noreferrer">
            <GooglePlayLogo />
          </a>
        </Logos>
        <a
          href="https://dynamicevents-ie.zoom.us/j/84325478767?pwd=K3p5RmpqUE40KzVtMHdyTWRFeURGdz09"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none'
          }}>
          <Button
            whileHover={{ scale: 1.035 }}
            whileTap={{ scale: 0.975 }}
            style={{ width: '10.875rem' }}>
            Play Game
          </Button>
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 5.188rem 1rem 0;
  width: 100%;
  ${({ style }) => style}

  > div {
    align-items: center;
    background-blend-mode: unset;
    background-color: #ae2dc8;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 34.25rem;
    padding: 2rem 1.5rem;
    position: relative;

    @media screen and (min-width: 68.75rem) {
      align-items: flex-start;
      height: auto;
      max-width: 71.65rem;
      padding: 3.063rem 3.375rem 1.875rem;
    }

    h2,
    p,
    button {
      position: relative;
      z-index: 1;
    }

    h2,
    p {
      color: #fff;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }

    h2 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 0.775em;

      @media screen and (min-width: 46.875rem) {
        text-align: left;
      }
    }

    p {
      font-size: 1rem;
      margin-bottom: 2em;
      max-width: 40.75rem;
      text-align: center;

      @media screen and (min-width: 46.875rem) {
        text-align: left;
      }
    }
  }
`;

const StyledQrCode = styled(QrCode)`
  max-width: 15.625rem;
`;

const PatientImpactImage = styled(GatsbyImage)`
  bottom: 0;
  position: absolute;
  right: 0;
`;

const Logos = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.875rem auto 0.95rem;
  max-width: 21.5rem;
  width: 100%;

  svg {
    height: 3rem;
    width: 10.125rem;

    &:first-of-type {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 460px) {
    align-items: unset;
    flex-direction:row;
    margin: 1.875rem auto;

    svg:first-of-type {
      margin-bottom: unset;
    }
  }

  @media screen and (min-width: 68.75rem) {
    margin: unset;
    bottom: 25px;
    position: absolute;
    right: 2.625rem;
  }
`;

export default PlayingForPatientImpact;
