import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  RegistrationForm,
  Button,
  Modal,
  HeroVideo,
  RenderWhenReady,
  HomepageBannerLoggedInGraphic
} from 'components';
import { FormLabel } from 'components/Forms/FormComponents';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { FirebaseContext, LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
// import { ITransformLogo, HomepageBannerLoggedInGraphic } from 'assets/svgs';
import { ITransformLogo } from 'assets/svgs';

const Header = (props) => {
  const { loading, firebase, user } = useContext(FirebaseContext);
  const { headerRef } = useContext(LocalContext);
  const { colors } = props;
  const { windowWidth } = useWindowSize();

  return (
    <Wrapper
      ref={headerRef}
      user={user}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <HeroVideo />
      <Container>
        <Content>
          <Date
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            colors={colors}>
            15<sup>th</sup> – 17<sup>th</sup> NOVEMBER
          </Date>
          <ITransformLogoWrapper
            initial={{ transform: 'rotateX(90deg)' }}
            animate={{ transform: 'rotateX(0deg)' }}
            transition={{ duration: 1, delay: 1 }}>
            <ITransformLogo />
          </ITransformLogoWrapper>
          <Title
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            Oncology GLocal
            <br />
            POA Meeting 2022
          </Title>
          <Description
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            Welcome to the Oncology GLocal POA meeting 2022. Over 3 days we&apos;re coming together
            to discuss strategic priorities for our portfolio, define clear action plans, celebrate
            successes and share innovative approaches from around the world.
          </Description>
          {windowWidth < 1150 && !loading && firebase && !user && (
            <RegisterButton
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <Button width="200px" type="button">
                <Modal
                  trigger={<p>Register Here</p>}
                  modalContent={<RegistrationForm colors={colors} contrast />}
                />
              </Button>
            </RegisterButton>
          )}
        </Content>
        <RenderWhenReady>
          {windowWidth >= 1150 && (
            <FormContainer
              user={user}
              style={
                user && {
                  display: 'flex',
                  justifyContent: 'center'
                }
              }
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              {loading ? null : !loading && firebase && user ? (
                <>
                  <HomepageBannerLoggedInGraphic />
                  {/* <HomepageBannerLoggedInGraphic width={300} style={{ marginBottom: '4rem' }} /> */}
                </>
              ) : (
                !loading && firebase && !user && <RegistrationForm colors={colors} contrast />
              )}
            </FormContainer>
          )}
        </RenderWhenReady>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  background: white;
  height: auto;
  overflow: ${({ user }) => (user ? 'hidden' : 'auto')};
  position: relative;
  width: 100%;

  @media (min-width: 1150px) {
    min-height: 100vh;
  }
`;

const Container = styled.div`
  color: var(--black);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 0 auto 1rem;
  max-width: 1440px;
  padding: 5rem 0.625rem 2.5rem;
  position: relative;
  width: 100%;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 6rem 0 12.5rem;
  }
  @media (min-width: 1150px) {
    /* padding: 12.5rem 5rem 0; */
    padding: 0rem 5rem;
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: flex-start;
  padding: 2rem 0.5rem;
  position: relative;
  width: 100%;
  z-index: 2;
  @media (min-width: 768px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/8;
    padding: 12.5rem 0 5.8rem;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
`;

const Date = styled(motion.h4)`
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: bold;
  margin-bottom: 2.75rem;

  @media (min-width: 1150px) {
    grid-row: 1/2;
    grid-column: 1/5;
  }
`;

const ITransformLogoWrapper = styled(motion.div)`
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin-bottom: 1.5rem;
  transform: rotateX(-90deg);
  transform-style: preserve-3d;
  p {
    font-size: 1rem;
    color: white;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  svg {
    height: 12.563rem;
    max-width: 100%;
  }
  @media (min-width: 1150px) {
    margin-bottom: 2rem;
    grid-row: 2/3;
    grid-column: 1/6;
  }
`;

const Title = styled(motion.h1)`
  color: white;
  font-size: 3rem;
  font-weight: bold;
  height: auto;
  margin-bottom: 0.25em;
  width: auto;
  @media (min-width: 1150px) {
    font-size: 3.5rem;
    margin-bottom: 2rem;
    grid-row: 3/4;
    grid-column: 1/7;
    svg {
      width: 30rem;
    }
  }

  @media (min-width: 1300px) {
    font-size: 4rem;
  }
`;

const Description = styled(motion.div)`
  color: white;
  font-size: 1.25rem;
  line-height: 1.3em;
  margin-bottom: 1.5rem;
  margin-top: 0.9rem;
  max-width: 32.5rem;
  @media (min-width: 1150px) {
    margin-bottom: 4rem;
    grid-row: 4/5;
    grid-column: 1/7;
  }

  @media (min-width: 1440px) {
    max-width: 34.4em;
  }
`;

const FormContainer = styled(motion.div)`
  grid-column: 1/7;
  height: auto;
  position: relative;
  z-index: 2;

  @media (min-width: 1150px) {
    grid-column: 8 /12;
    padding-top: ${({ user }) => (user ? '9rem' : '10.5rem')};
  }
`;

const RegisterButton = styled(motion.div)`
  margin-left: 0.5rem;
  margin-top: 1rem;
  button {
    align-items: center;
    background-color: #b12f7e;
    display: flex;
    justify-content: center;
    padding: 1.75rem 1rem;
  }
`;

export default Header;
