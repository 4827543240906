import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, CustomError, ErrorMessage, LoadingSpinner } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { clearQueryParams, scrollToAnchor } from 'utils';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { FormContainer, FormLabel, FormInput } from '../FormComponents';

const LoginModal = ({
  setShowLoginModal,
  lastUserInteractionWasFromAnotherDeviceOrBrowser,
  colors
}) => {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [formValues, setFormValues] = useState({
    email: ''
  });
  const [magicLinkEmailStatus, setMagicLinkEmailStatus] = useState({
    sending: false,
    sent: false
  });
  const [error, setError] = useState({
    code: '',
    message: lastUserInteractionWasFromAnotherDeviceOrBrowser
      ? 'It seems your last website interaction was from another device or browser. Please enter your email address to be sent a new link, which you can click to continue with this browser.'
      : ''
  });

  const handleInputChange = (e) => {
    if (!lastUserInteractionWasFromAnotherDeviceOrBrowser) {
      setError({
        code: '',
        message: ''
      });
    }

    const { name, value } = e.target;
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (lastUserInteractionWasFromAnotherDeviceOrBrowser && error.code === 'auth/user-not-found') {
      clearQueryParams();
      setShowLoginModal(false);
      scrollToAnchor('registration-form', {
        duration: 1000,
        offset: -90
      });
      return;
    }

    if (!lastUserInteractionWasFromAnotherDeviceOrBrowser) {
      setError({
        code: '',
        message: ''
      });
    }

    const { email } = formValues;

    try {
      setMagicLinkEmailStatus({
        sending: true,
        sent: false
      });

      const { data: userExists } = await firebase.checkIfUserAlreadyExists({ email });

      if (!userExists) {
        setMagicLinkEmailStatus({
          sending: false,
          sent: false
        });
        throw new CustomError('auth/user-not-found');
      }

      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      };

      const { data } = await firebase.sendSignInWithMagicLinkEmail({
        firstName: '',
        email,
        actionCodeSettings,
        colors
      });

      if (data?.Message === 'OK') {
        setMagicLinkEmailStatus({
          sending: false,
          sent: true
        });
        window.localStorage.setItem(
          'existingUseriTransform2022',
          JSON.stringify({
            ...formValues
          })
        );
        if (lastUserInteractionWasFromAnotherDeviceOrBrowser) {
          clearQueryParams();
        }
        if (error.message) {
          setError({
            code: '',
            message: ''
          });
        }
      }
    } catch (_error) {
      setMagicLinkEmailStatus({
        sending: false,
        sent: false
      });
      switch (_error.code) {
        case 'permission-denied':
          console.error(_error);
          setError({
            code: _error.code,
            message: 'Error logging in. Please contact support'
          });
          break;
        case 'auth/user-not-found':
          console.error(_error.toString());
          setError({
            code: _error.code,
            message: lastUserInteractionWasFromAnotherDeviceOrBrowser
              ? 'There is no user account associated with this email address. Your default/current browser is likely different than the browser you tried registering with. Click below to register again using this default/current browser.'
              : 'There is no user account associated<br />with this email address.'
          });
          break;
        default:
          console.error(_error);
          setError({
            code: _error.code,
            message: _error.message
          });
          break;
      }
    }
  };

  return (
    <StyledFormContainer
      onSubmit={handleSubmit}
      theme={theme}
      colors={colors}
      lastUserInteractionWasFromAnotherDeviceOrBrowser={
        lastUserInteractionWasFromAnotherDeviceOrBrowser
      }>
      {!magicLinkEmailStatus.sent && (
        <>
          <FormLabel>
            {lastUserInteractionWasFromAnotherDeviceOrBrowser ? 'Whoops...' : 'Login'}
          </FormLabel>
          {(error.code !== 'auth/user-not-found' ||
            (error.code === 'auth/user-not-found' &&
              !lastUserInteractionWasFromAnotherDeviceOrBrowser)) && (
            <>
              <FormLabel hide htmlFor="email">
                Email
              </FormLabel>
              <FormInput
                id="email"
                name="email"
                onChange={handleInputChange}
                placeholder="Email"
                type="email"
                value={formValues.email}
                required
                style={{
                  marginBottom: '1.5rem'
                }}
              />
            </>
          )}
        </>
      )}
      <ErrorMessage
        errorMessage={error.message}
        style={{
          marginBottom: '0.575em'
        }}
        variants={fadeInAndOutAndAnimateHeightVariants()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row'
        }}>
        {magicLinkEmailStatus.sent ? (
          <h3
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              letterSpacing: '0.01em',
              maxWidth: '17em',
              textAlign: 'center'
            }}>
            Please click the link in the email we&apos;ve just sent you to log in.
          </h3>
        ) : (
          <Button
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            type="submit"
            whileHover={{
              scale: 1.05
            }}
            whileTap={{
              scale: 0.95
            }}
            width={lastUserInteractionWasFromAnotherDeviceOrBrowser ? '240px' : '120px'}>
            {magicLinkEmailStatus.sending ? (
              <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
            ) : lastUserInteractionWasFromAnotherDeviceOrBrowser &&
              error.code === 'auth/user-not-found' ? (
              'Register'
            ) : lastUserInteractionWasFromAnotherDeviceOrBrowser &&
              error.code !== 'auth/user-not-found' ? (
              'Send'
            ) : (
              'Login'
            )}
          </Button>
        )}
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};

  button {
    background: ${({ colors }) => colors.secondary};
  }

  ${({ lastUserInteractionWasFromAnotherDeviceOrBrowser }) =>
    lastUserInteractionWasFromAnotherDeviceOrBrowser &&
    css`
      @media (min-width: 1440px) {
        padding: 2.5rem 1.25rem;
        max-width: 510px;
        width: 100%;
      }
    `}
`;

export default LoginModal;
