import React, { useContext } from 'react';
import styled from 'styled-components';
import { AgencyxLogo } from 'assets/svgs';
import { LocalContext } from 'context';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';

export default ({ eventDepartmentLogo, eventJobCode, eventFooterText, dateOfPrep }) => {
  const { contactRef } = useContext(LocalContext);
  return (
    <Footer ref={contactRef} id="contact">
      <Container>
        <div>
          {eventDepartmentLogo && (
            <EventLogo image={getImage(eventDepartmentLogo)} alt="Event Department Logo" />
          )}
          <p>
            <Markup content={eventFooterText} />
          </p>
          <a href="https://www.agencyx.ie/" target="__blank">
            <AgencyxLogo
              style={{
                width: '200px'
              }}
            />
          </a>
        </div>
        <div />
      </Container>
      <Copyright>
        <div>
          <p>
            Copyright © 2021 - Designed and Developed by Agency X
            {eventJobCode && dateOfPrep && (
              <>
                <br />
                <span>{`${eventJobCode} | Date of preparation: ${dateOfPrep}`}</span>
              </>
            )}
          </p>
          <p>
            <a href="https://agencyx.ie/terms" target="blank">
              Terms & Conditions
            </a>
            <Link to="/privacy">Privacy Policy</Link>
          </p>
        </div>
      </Copyright>
    </Footer>
  );
};

const EventLogo = styled(GatsbyImage)`
  height: auto;
  margin-bottom: 1.25rem;
  margin-top: 1rem;
  max-height: 5rem;
  width: 12.5rem;
  z-index: 1;
  img {
    object-fit: contain !important;
  }
`;

const Container = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin: 0 auto;
  max-width: 1440px;
  padding: 3rem 1rem;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    grid-column: 1/7;
    justify-content: flex-start;
    justify-content: start;
    svg {
      width: 12.5rem;
      margin-bottom: 2rem;
      :last-of-type {
        width: 8rem;
      }
    }
    h5 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    p {
      margin: 0.1rem 0 2.15rem;
    }

    a {
      color: #000;
      text-decoration: underline;
    }

    @media (min-width: 1150px) {
      :first-child {
        grid-column: 2/8;
      }
      :last-child {
        grid-column: 10/12;
        justify-content: end;
      }
    }
  }

  @media (min-width: 1150px) {
    flex-direction: row;
    padding: 3rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Copyright = styled.div`
  background: #c4c4c4;
  height: auto;
  padding: 1rem;
  width: 100%;
  > div {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 auto;
    max-width: 1440px;
    p {
      color: #3c3c3c;
      font-size: 0.875rem;
      grid-column: 1/7;
      margin: 1rem 0;

      &:first-of-type > span {
        color: #3c3c3c;
      }

      a {
        color: #3c3c3c;
        margin-right: 3rem;
      }
    }
    @media (min-width: 1150px) {
      grid-template-columns: repeat(12, 1fr);
      p {
        &:first-child {
          grid-column: 2/7;
        }
        &:last-child {
          grid-column: 8/12;
          text-align: right;
        }
      }
    }
  }
`;

const Footer = styled.footer`
  background: #ededed;
  width: 100%;
`;
