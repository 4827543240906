import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Modal, KioskEntryPopup } from 'components';
import Interweave from 'interweave';

const Kiosk = ({ kiosk, colors }) => (
  <Wrapper>
    <h2>Pre-Reads and Additional Info</h2>
    <KioskEntries>
      {kiosk.map((kioskEntry, i) => (
        <KioskEntry key={i}>
          <KioskThumbnail image={getImage(kioskEntry.thumbnail)} alt={kioskEntry.title} />
          <Details colors={colors}>
            <div>
              <h3>{kioskEntry.title}</h3>
              <p>
                <Interweave content={kioskEntry.excerpt} noWrap />
              </p>
            </div>
            <Modal
              colors={colors}
              trigger={
                <ReadMore $bgColor={kioskEntry.color} type="button">
                  Read More
                </ReadMore>
              }
              modalContent={<KioskEntryPopup colors={colors} kioskEntry={kioskEntry} />}
            />
          </Details>
        </KioskEntry>
      ))}
    </KioskEntries>
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 67px;

  h2 {
    color: #3c3c3c;
    font-size: 1.5rem;
    margin-bottom: 2.1em;
    text-align: center;
    width: 100%;
  }
`;

const KioskEntries = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1184px;
  width: 100%;
`;

const KioskEntry = styled.div`
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 10px 20px;
  max-width: 572px;
  text-align: left;
  width: 100%;
`;

const KioskThumbnail = styled(GatsbyImage)`
  flex-shrink: 0;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 20px 28px;
  width: 100%;

  h3,
  p {
    font-size: 1.25rem;
  }

  h3 {
    color: #0095ff;
    font-weight: 700;
  }
  p {
    color: #3c3c3c;
    margin-bottom: 1.4em;
  }
`;

const ReadMore = styled.button`
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 50px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s;
  width: 10rem;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

export default Kiosk;
