import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from 'context';
import styled, { css } from 'styled-components';
import { Chat, Polls, ParticipantModal, Participants, QAndA } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { breakpoints } from 'styles';

const Interaction = ({
  colors,
  did,
  eid,
  forcedActiveTab,
  questionCurrentlyBeingAnsweredLive,
  dbEventTitle,
  isQAndAEnabled,
  isPollsEnabled,
  isParticipantsEnabled
}) => {
  const { firebase, user } = useContext(FirebaseContext);
  const [activeTab, setActiveTab] = useState('Chat');
  const [showParticipantModal, setShowParticipantModal] = useState(false);

  useEffect(() => {
    if (
      user &&
      (!user.isModerator || !user.isModerator?.includes(`${did}_${eid}`)) &&
      forcedActiveTab &&
      activeTab !== forcedActiveTab
    ) {
      setActiveTab(forcedActiveTab);
    }
    return () => {
      if (user?.isModerator?.includes(`${did}_${eid}`) && forcedActiveTab) {
        firebase.forceActiveTab({ eid, did, tabName: null });
      }
    };
  }, [user, forcedActiveTab, did, eid]);

  useEffect(() => {
    if (showParticipantModal) {
      setShowParticipantModal(false);
    }
  }, [activeTab, forcedActiveTab, did, eid]);

  const handleParticipantAvatarOrNameClick = (participant) => {
    if (participant) {
      setShowParticipantModal({
        avatarUrl: participant.avatarUrl,
        name: participant.name,
        function: participant.function,
        region: participant.region,
        country: participant.country
      });
    } else {
      setShowParticipantModal(false);
    }
  };

  return (
    <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Tabs>
        <Tab isActive={activeTab === 'Chat'} onClick={() => setActiveTab('Chat')} colors={colors}>
          Chat
        </Tab>
        {isQAndAEnabled && (
          <Tab isActive={activeTab === 'Q&A'} onClick={() => setActiveTab('Q&A')} colors={colors}>
            Q & A
          </Tab>
        )}
        {isPollsEnabled && (
          <Tab
            isActive={activeTab === 'Polls'}
            onClick={() => setActiveTab('Polls')}
            colors={colors}>
            Polls
          </Tab>
        )}
        {isParticipantsEnabled && (
          <Tab
            isActive={activeTab === 'Participants'}
            onClick={() => setActiveTab('Participants')}
            colors={colors}>
            Participants
          </Tab>
        )}
      </Tabs>
      <Modules>
        <AnimatePresence>
          {activeTab === 'Chat' && (
            <Chat
              colors={colors}
              did={did}
              eid={eid}
              handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            />
          )}
        </AnimatePresence>
        {isQAndAEnabled && (
          <AnimatePresence>
            {activeTab === 'Q&A' && (
              <QAndA
                colors={colors}
                did={did}
                eid={eid}
                questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
              />
            )}
          </AnimatePresence>
        )}
        {isPollsEnabled && (
          <AnimatePresence>
            {activeTab === 'Polls' && <Polls did={did} eid={eid} colors={colors} />}
          </AnimatePresence>
        )}
        {isParticipantsEnabled && (
          <>
            <AnimatePresence>
              {activeTab === 'Participants' && (
                <Participants
                  colors={colors}
                  did={did}
                  eid={eid}
                  dbEventTitle={dbEventTitle}
                  showParticipantModal={showParticipantModal}
                  handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {showParticipantModal && (
                <ParticipantModal
                  colors={colors}
                  handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                  showParticipantModal={showParticipantModal}
                />
              )}
            </AnimatePresence>
          </>
        )}
      </Modules>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-self: center;
  max-width: 500px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${breakpoints.lg}) {
    justify-self: unset;
    max-width: unset;
    height: 100%;
  }
`;

const Modules = styled.div`
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-top: none;
  height: 100%;
  margin-top: 2.875rem;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;

const Tabs = styled.div`
  align-items: flex-end;
  background-color: transparent;
  display: flex;
  height: 2.875rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Tab = styled.div`
  align-items: center;
  border: 0.071em solid #bdbdbd;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  padding: 0 1.125em;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #fff;
          border-top-color: ${({ colors }) => colors.secondary};
          border-top-width: 0.357em;
          color: #3c3c3c;
          height: 3.286em;
          width: 51%;
        `
      : css`
          background-color: #e5e5e5;
          border-top-color: #bdbdbd;
          border-top-width: 0.071em;
          color: #bdbdbd;
          height: 2.929em;
          width: 49%;

          &:first-child {
            border-right: none;
          }

          &:last-child {
            border-left: none;
          }
        `}
`;

export default Interaction;
