import React from 'react';
import styled, { keyframes } from 'styled-components';

const AddToCalendar = ({ fileName }) => (
  <a href={`/${fileName}`} target="_blank" rel="noreferrer">
    <Plus />
  </a>
);

const color = keyframes`
    0% {
      background-color: #00004E;
    }
  
    50% {
      background-color: #0095FF;
    }
    
    100% {
      background-color: #00004E;
    }
  `;

const Plus = styled.div`
  animation: ${color} 2s infinite;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), #00004e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 2px, 2px 50%;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin-top: 8px;
  width: 20px;
`;

export default AddToCalendar;
