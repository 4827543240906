import * as React from 'react';

function SvgTarget4(props) {
  return (
    <svg viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.206 10.307a.861.861 0 01-.44-.112 62.43 62.43 0 00-30.755-8.089 61.883 61.883 0 00-25.197 5.301.903.903 0 01-1.187-.465.86.86 0 01.465-1.179A63.75 63.75 0 0165.012.316a64.222 64.222 0 0131.641 8.322.86.86 0 01.336 1.222.86.86 0 01-.783.447zM27.81 101.771a52.703 52.703 0 0037.21 15.432 52.702 52.702 0 0052.639-52.64 52.709 52.709 0 00-52.64-52.647 52.708 52.708 0 00-52.647 52.648A52.7 52.7 0 0027.81 101.77zm1.266-73.148A50.91 50.91 0 0165.02 13.715a50.903 50.903 0 0150.849 50.849 50.908 50.908 0 01-50.85 50.849 50.91 50.91 0 01-50.857-50.85 50.91 50.91 0 0114.914-35.94zM3.244 81.605a.86.86 0 00.548.178l.242-.025a.861.861 0 00.628-1.102A62.819 62.819 0 012.57 64.564a62.87 62.87 0 011.961-15.601.861.861 0 00-.645-1.093.86.86 0 00-1.084.645A64.394 64.394 0 00.78 64.565 64.454 64.454 0 002.93 81.12a.86.86 0 00.313.484zm27.63 37.225a.862.862 0 01-.482-.147A64.536 64.536 0 017.949 94.098a.86.86 0 01.387-1.205.86.86 0 011.205.379 62.745 62.745 0 0021.858 23.905.865.865 0 01.267 1.24.867.867 0 01-.792.413zm13.21 6.531a64.08 64.08 0 0020.927 3.442 64.253 64.253 0 0020.146-3.175.854.854 0 00.534-.443.863.863 0 00.033-.693.858.858 0 00-1.127-.568 62.295 62.295 0 01-19.586 3.132 62.201 62.201 0 01-20.343-3.39.86.86 0 00-1.145.559.903.903 0 00.56 1.136zm60.779-10.662a.914.914 0 01-.697-.327.852.852 0 01-.206-.658.864.864 0 01.344-.598 62.689 62.689 0 0013.106-14.56.858.858 0 011.239-.259.862.862 0 01.267 1.24 64.55 64.55 0 01-13.485 14.964.865.865 0 01-.568.198zm19.525-27.976a.866.866 0 00.302.052.86.86 0 00.843-.594 64.18 64.18 0 003.726-21.617 63.682 63.682 0 00-20.18-46.753.85.85 0 00-.642-.256.859.859 0 00-.623.299.87.87 0 00-.255.642.85.85 0 00.299.623 61.907 61.907 0 0119.611 45.445 62.363 62.363 0 01-3.623 21.014.862.862 0 00.542 1.145zM12.088 29.73a.86.86 0 00.5.154.86.86 0 00.748-.395 62.42 62.42 0 017.83-9.398.862.862 0 000-1.265.903.903 0 00-1.273 0 64.543 64.543 0 00-8.046 9.664.861.861 0 00.241 1.24z"
        fill="url(#target-4_svg__paint0_linear_224_10)"
      />
      <defs>
        <linearGradient
          id="target-4_svg__paint0_linear_224_10"
          x1={16.563}
          y1={18.564}
          x2={102.221}
          y2={118.675}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBE38" />
          <stop offset={0.453} stopColor="#FF4937" />
          <stop offset={1} stopColor="#BD00CE" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTarget4;
