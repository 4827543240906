/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import AddToCalendar from './AddToCalendar';

const AgendaAllDaysVisibleVersion = () => {
  const { windowWidth } = useWindowSize();
  return (
    <div style={{ margin: windowWidth < 1200 ? '0 1rem' : 0 }}>
      {windowWidth < 768 ? (
        <>
          <Agenda id="agenda">Agenda Overview</Agenda>
          <P>Click on any of the flashing icons below to add that event to your calendar.</P>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th style={{ color: '#bd00ce' }}>
                  Day 1 - MON 15<sup>th</sup> NOV
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  03:30 NY
                  <br />
                  09:30 CET
                </td>
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.2)' }}>
                  <strong>
                    iTransform - Welcome
                    <br />
                    from Andy and Dany
                  </strong>
                  <br />
                  <AddToCalendar fileName="iTransform-Welcome-from-Andy-and-Dany.ics" />
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.4)' }}>
                  <strong>
                    The Impact of Personal
                    <br />
                    Transformation
                    <br />
                    External Speaker
                  </strong>
                  <br />
                  <AddToCalendar fileName="the-impact-of-personal-transformation.ics" />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <strong>Break</strong>&nbsp;15mins
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.2)' }}>
                  <strong>Let&apos;s hear from our Global Franchise & Medical Leads</strong>
                  <br />
                  <AddToCalendar fileName="global-franchise-medical-leads.ics" />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <strong>Break</strong>&nbsp;10mins
                </td>
              </tr>
              <tr>
                <td>
                  Finish
                  <br />
                  11:00 NY
                  <br />
                  17:00 CET
                </td>
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.2)' }}>
                  <strong>Playing for Patient Impact - #Joy</strong>
                  <br />
                  <AddToCalendar fileName="playing-for-patient-impact-joy.ics" />
                </td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th style={{ color: '#ff4937' }} colSpan={2}>
                  Day 2 - TUES 16<sup>th</sup> NOV
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  03:30 NY
                  <br />
                  09:30 CET
                </td>
                <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>
                    Supercharge IDM for Personal Growth,
                    <br />
                    Business Growth &<br />
                    Patient Impact
                  </strong>
                  <br />
                  <AddToCalendar fileName="supercharge-IDM-for-personal-growth-business-growth-and-patient-impact.ics" />
                </td>
              </tr>
              <tr>
                <td />
                <td colSpan={2}>
                  <strong>Break</strong>&nbsp;15mins
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>
                    Bold Move 3.3 – The UK
                    <br />
                    Experience
                  </strong>
                  <br />
                  <AddToCalendar fileName="bold-move-the-uk-experience.ics" />
                </td>
              </tr>
              <tr>
                <td style={{ height: '35px' }} colSpan={2} />
              </tr>
              <tr>
                <td>
                  8:00 NY
                  <br />
                  14:00 CET
                </td>
                {/* <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>In Conversation with Franchises</strong>
                </td> */}
                <td
                  width="49.81949458483754%"
                  style={{ backgroundColor: 'rgba(255, 73, 55, 0.5)', height: '186px' }}>
                  <strong>Hematology</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="hematology-franchise-session.ics" />
                </td>
                <td
                  width="49.81949458483754%"
                  style={{ backgroundColor: 'rgba(255, 190, 56, 0.5)', height: '186px' }}>
                  <strong>Breast</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="breast-franchise-session.ics" />
                </td>
              </tr>
              <tr>
                <td />
                <td />
                {/* <td
                  width="49.81949458483754%"
                  style={{ backgroundColor: 'rgba(255, 73, 55, 0.5)', height: '186px' }}>
                  <strong>Hematology</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="hematology-franchise-session.ics" />
                </td>
                <td
                  width="49.81949458483754%"
                  style={{ backgroundColor: 'rgba(255, 190, 56, 0.5)', height: '186px' }}>
                  <strong>Breast</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="breast-franchise-session.ics" />
                </td> */}
              </tr>
              <tr>
                <td />
                <td colSpan={2}>
                  <strong>Break</strong>&nbsp;15mins
                </td>
              </tr>
              <tr>
                <td>
                  Finish
                  <br />
                  11:00 NY
                  <br />
                  17:00 CET
                </td>
                <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>Closing the day with #Joy</strong>
                  <br />
                  <AddToCalendar fileName="closing-the-day-with-joy.ics" />
                </td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th style={{ color: '#479244' }} colSpan={3}>
                  Day 3 - WED 17<sup>th</sup> NOV
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  03:30 NY
                  <br />
                  09:30 CET
                </td>
                <td style={{ backgroundColor: 'rgba(71, 146, 68, 0.2)' }} colSpan={3}>
                  <strong>
                    Science Will Win!
                    <br />
                    Learnings from Our Vaccine Story
                  </strong>
                  <br />
                  <AddToCalendar fileName="science-will-win-learnings-from-our-vaccine-story.ics" />
                </td>
              </tr>
              <tr>
                <td style={{ height: '35px' }} colSpan={3} />
              </tr>
              <tr>
                <td>
                  8:00 NY
                  <br />
                  14:00 CET
                </td>
                {/* <td style={{ backgroundColor: 'rgba(71, 146, 68, 0.2)' }} colSpan={3}>
                  <strong>In Conversation with Franchises</strong>
                </td> */}
                <td
                  width="33.2129963898917%"
                  style={{ backgroundColor: 'rgba(71, 146, 68, 0.5)', height: '186px' }}>
                  <strong>Breast</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="breast-franchise-session-2.ics" />
                  <br />
                  <br />
                  <strong>Lung</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="lung-franchise-session.ics" />
                </td>
                <td
                  width="33.2129963898917%"
                  style={{ backgroundColor: 'rgba(10, 70, 90, 0.3)', height: '186px' }}>
                  <strong>Biosims</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="biosims-franchise-session.ics" />
                </td>
                <td
                  width="33.2129963898917%"
                  style={{ backgroundColor: 'rgba(99, 193, 197, 0.4)', height: '186px' }}>
                  <strong>GU</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="gu-franchise-session.ics" />
                </td>
              </tr>
              <tr>
                <td />
                {/* <td
                  width="33.2129963898917%"
                  style={{ backgroundColor: 'rgba(71, 146, 68, 0.5)', height: '186px' }}>
                  <strong>Breast</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="breast-franchise-session-2.ics" />
                  <br />
                  <br />
                  <strong>Lung</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="lung-franchise-session.ics" />
                </td>
                <td
                  width="33.2129963898917%"
                  style={{ backgroundColor: 'rgba(10, 70, 90, 0.3)', height: '186px' }}>
                  <strong>Biosims</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="biosims-franchise-session.ics" />
                </td>
                <td
                  width="33.2129963898917%"
                  style={{ backgroundColor: 'rgba(99, 193, 197, 0.4)', height: '186px' }}>
                  <strong>GU</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="gu-franchise-session.ics" />
                </td> */}
              </tr>
              <tr>
                <td />
                <td colSpan={3}>
                  <strong>Break</strong>&nbsp;15mins
                </td>
              </tr>
              <tr>
                <td>
                  Finish
                  <br />
                  11:00 NY
                  <br />
                  17:00 CET
                </td>
                <td style={{ backgroundColor: 'rgba(71, 146, 68, 0.2)' }} colSpan={3}>
                  <strong>Transforming for the Future</strong>
                  <br />
                  Close and Wrap Up
                  <br />
                  <AddToCalendar fileName="transforming-for-the-future.ics" />
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Agenda id="agenda">Agenda Overview</Agenda>
          <P>Click on any of the flashing icons below to add that event to your calendar.</P>
          <Table>
            <thead>
              <tr>
                <th>Time:</th>
                <th style={{ width: '12px' }} />
                <th style={{ color: '#bd00ce' }}>
                  Day 1 - MON 15<sup>th</sup> NOV
                </th>
                <th style={{ width: '12px' }} />
                <th style={{ color: '#ff4937' }} colSpan={2}>
                  Day 2 - TUES 16<sup>th</sup> NOV
                </th>
                <th style={{ width: '12px' }} />
                <th style={{ color: '#479244' }} colSpan={3}>
                  Day 3 - WED 17<sup>th</sup> NOV
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  03:30 NY
                  <br />
                  09:30 CET
                </td>
                <td style={{ width: '12px' }} />
                <td />
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>
                    Supercharge IDM for Personal Growth,
                    <br />
                    Business Growth & Patient Impact
                  </strong>
                  <br />
                  <AddToCalendar fileName="supercharge-IDM-for-personal-growth-business-growth-and-patient-impact.ics" />
                </td>
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(71, 146, 68, 0.2)' }} colSpan={3}>
                  <strong>
                    Science Will Win!
                    <br />
                    Learnings from Our Vaccine Story
                  </strong>
                  <br />
                  <AddToCalendar fileName="science-will-win-learnings-from-our-vaccine-story.ics" />
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ width: '12px' }} />
                <td />
                <td style={{ width: '12px' }} />
                <td colSpan={2}>
                  <strong>Break</strong>&nbsp;15 Mins
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ width: '12px' }} />
                <td />
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>Bold Move 3.3 – The UK Experience</strong>
                  <br />
                  <AddToCalendar fileName="bold-move-the-uk-experience.ics" />
                </td>
              </tr>
              <tr style={{ height: '37px' }} />
              <tr>
                <td>
                  8:00 NY
                  <br />
                  14:00 CET
                </td>
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.2)' }}>
                  <strong>
                    iTransform - Welcome from
                    <br />
                    Andy and Dany
                  </strong>
                  <br />
                  <AddToCalendar fileName="iTransform-Welcome-from-Andy-and-Dany.ics" />
                </td>
                {/* <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>In Conversation with Franchises</strong>
                </td>
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(71, 146, 68, 0.2)' }} colSpan={3}>
                  <strong>In Conversation with Franchises</strong>
                </td> */}
                <td />
                <td
                  width="14.57975986277873%"
                  rowSpan={4}
                  style={{ backgroundColor: 'rgba(255, 73, 55, 0.5)' }}>
                  <strong>Hematology</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="hematology-franchise-session.ics" />
                </td>
                <td
                  width="14.57975986277873%"
                  rowSpan={4}
                  style={{ backgroundColor: 'rgba(255, 190, 56, 0.5)' }}>
                  <strong>Breast</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="breast-franchise-session.ics" />
                </td>
                <td />
                <td
                  width="9.691252144082332%"
                  rowSpan={4}
                  style={{ backgroundColor: 'rgba(71, 146, 68, 0.5)' }}>
                  <strong>Breast</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="breast-franchise-session-2.ics" />
                  <br />
                  <br />
                  <strong>Lung</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="lung-franchise-session.ics" />
                </td>
                <td
                  width="9.691252144082332%"
                  rowSpan={4}
                  style={{ backgroundColor: 'rgba(10, 70, 90, 0.3)' }}>
                  <strong>Biosims</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="biosims-franchise-session.ics" />
                </td>
                <td
                  width="9.691252144082332%"
                  rowSpan={4}
                  style={{ backgroundColor: 'rgba(99, 193, 197, 0.4)' }}>
                  <strong>GU</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="gu-franchise-session.ics" />
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.4)' }}>
                  <strong>The Impact of Personal Transformation</strong>
                  <br />
                  <strong>External Speaker</strong>
                  <br />
                  <AddToCalendar fileName="the-impact-of-personal-transformation.ics" />
                </td>
                <td style={{ width: '12px' }} />
                <td style={{ width: '12px' }} />
                {/* <td
                  width="9.691252144082332%"
                  rowSpan={3}
                  style={{ backgroundColor: 'rgba(71, 146, 68, 0.5)' }}>
                  <strong>Breast</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="breast-franchise-session-2.ics" />
                  <br />
                  <br />
                  <strong>Lung</strong>
                  <br />
                  45mins
                  <br />
                  <AddToCalendar fileName="lung-franchise-session.ics" />
                </td>
                <td
                  width="9.691252144082332%"
                  rowSpan={3}
                  style={{ backgroundColor: 'rgba(10, 70, 90, 0.3)' }}>
                  <strong>Biosims</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="biosims-franchise-session.ics" />
                </td>
                <td
                  width="9.691252144082332%"
                  rowSpan={3}
                  style={{ backgroundColor: 'rgba(99, 193, 197, 0.4)' }}>
                  <strong>GU</strong>
                  <br />
                  90mins
                  <br />
                  <AddToCalendar fileName="gu-franchise-session.ics" />
                </td> */}
              </tr>
              <tr>
                <td />
                <td style={{ width: '12px' }} />
                <td>
                  <strong>Break</strong>&nbsp;15mins
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.2)' }}>
                  <strong>
                    Let&apos;s hear from our Global Franchise &<br />
                    Medical Leads
                  </strong>
                  <br />
                  <AddToCalendar fileName="global-franchise-medical-leads.ics" />
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ width: '12px' }} />
                <td>
                  <strong>Break</strong>&nbsp;10mins
                </td>
                <td style={{ width: '12px' }} />
                <td colSpan={2}>
                  <strong>Break</strong>&nbsp;15mins
                </td>
                <td style={{ width: '12px' }} />
                <td colSpan={3}>
                  <strong>Break</strong>&nbsp;15mins
                </td>
              </tr>
              <tr>
                <td style={{ padding: 0, lineHeight: '1.55em' }}>
                  Finish
                  <br />
                  11:00 NY
                  <br />
                  17:00 CET
                </td>
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(189, 0, 206, 0.2)' }}>
                  <strong>Playing for Patient Impact - #Joy</strong>
                  <br />
                  <AddToCalendar fileName="playing-for-patient-impact-joy.ics" />
                </td>
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(255, 73, 55, 0.2)' }} colSpan={2}>
                  <strong>Closing the day with #JOY!</strong>
                  <br />
                  <AddToCalendar fileName="closing-the-day-with-joy.ics" />
                </td>
                <td style={{ width: '12px' }} />
                <td style={{ backgroundColor: 'rgba(71, 146, 68, 0.2)' }} colSpan={3}>
                  <strong>Transforming for the Future</strong>
                  <br />
                  Close and Wrap Up
                  <br />
                  <AddToCalendar fileName="transforming-for-the-future.ics" />
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

const Table = styled.table`
  margin: 0 auto 69px;
  max-width: 27.5rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto 4.063rem;
    max-width: 72.875rem;
  }

  &,
  th,
  td {
    border-collapse: collapse;
  }

  thead th {
    text-align: center;

    &:nth-of-type(1) {
      text-align: left;
      position: relative;
      top: 0.063rem;
    }
  }

  tbody td {
    vertical-align: center;
  }

  tbody tr td:nth-of-type(1) {
    white-space: nowrap;
  }

  th {
    font-size: 1rem;
    text-transform: uppercase;

    sup {
      text-transform: lowercase;
    }
  }

  td {
    color: #3c3c3c;
    font-size: 0.75rem;
    line-height: 1.643em;

    &:not(:first-of-type) {
      text-align: center;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 768px) {
      &:first-of-type {
        font-size: 0.875rem;
      }
    }
  }

  th,
  td {
    padding: 0.5rem 0.75rem 0.5rem 0;
  }

  @media screen and (min-width: 768px) {
    padding: 0.625rem;
  }
`;

const Agenda = styled.h2`
  font-size: 1.5rem;
  margin: 1.083em auto 0.5em;
  max-width: 27.5rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 4.15em auto 0.75em;
    max-width: 72.875rem;
    width: 100%;
  }
`;

const P = styled.p`
  font-size: 1rem;
  margin: 0 auto 1em;
  max-width: 27.5rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto 1.5em;
    max-width: 72.875rem;
  }
`;

export default AgendaAllDaysVisibleVersion;
