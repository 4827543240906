import React from 'react';
import styled from 'styled-components';
import { KioskEntryPopup, Modal } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { PrevArrow, NextArrow } from 'assets/svgs';
import { Markup } from 'interweave';
import { useWindowSize } from 'hooks';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const EventCarousel = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativePath: { regex: "/data/days/" } }) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  events {
                    kiosk {
                      day
                      color
                      title
                      thumbnail {
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED
                            formats: [AUTO, WEBP, AVIF]
                            width: 374
                            height: 240
                            placeholder: TRACED_SVG
                          )
                        }
                      }
                      banner {
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED
                            formats: [AUTO, WEBP, AVIF]
                            width: 1164
                            height: 420
                            placeholder: TRACED_SVG
                          )
                        }
                      }
                      excerpt
                      calendarLink
                      content
                      videos {
                        thumbnail {
                          childImageSharp {
                            gatsbyImageData(
                              layout: CONSTRAINED
                              formats: [AUTO, WEBP, AVIF]
                              width: 453
                              height: 268
                              placeholder: TRACED_SVG
                            )
                          }
                        }
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const days = data.allFile.edges;

  const kiosks = days
    .map(({ node }) => node.childMarkdownRemark.frontmatter.events)
    .reduce((acc, arr) => {
      acc.push(...arr);
      return acc;
    }, [])
    .filter(({ kiosk }) => kiosk)
    .map(({ kiosk }) => kiosk[0])
    .sort((a, b) => a.day - b.day);

  const { windowWidth } = useWindowSize();

  return (
    <Wrapper>
      <h3>Find out more about individual events</h3>
      <CarouselProvider
        naturalSlideWidth={374}
        naturalSlideHeight={240}
        totalSlides={5}
        visibleSlides={windowWidth < 768 ? 1 : windowWidth < 1200 ? 2 : 3}
        isIntrinsicHeight
        isPlaying
        interval={6000}>
        <CarouselWrapper>
          <StyledButtonBack>
            <PrevArrow width="0.75rem" />
          </StyledButtonBack>
          <StlyedSlider>
            {kiosks.map((kiosk, i) => (
              <StyledSlide color={kiosk.color} key={kiosk.title} index={i}>
                <Modal
                  colors={{
                    secondary: kiosk.color
                  }}
                  trigger={<GatsbyImage image={getImage(kiosk.thumbnail)} alt={kiosk.title} />}
                  modalContent={<KioskEntryPopup kioskEntry={kiosk} />}
                />
              </StyledSlide>
            ))}
          </StlyedSlider>
          <StyledButtonNext>
            <NextArrow width="0.75rem" />
          </StyledButtonNext>
        </CarouselWrapper>
        <CarouselWrapper>
          <StlyedSlider>
            {kiosks.map((kiosk, i) => (
              <StyledSlide color={kiosk.color} key={kiosk.title} index={i}>
                <Modal
                  colors={{
                    secondary: kiosk.color
                  }}
                  trigger={
                    <>
                      <p>{`Day ${kiosk.day}`}</p>
                      <p>
                        <Markup content={kiosk.title} noWrap />
                      </p>
                      <p>Pre-reads & additional info</p>
                    </>
                  }
                  modalContent={<KioskEntryPopup kioskEntry={kiosk} />}
                />
              </StyledSlide>
            ))}
          </StlyedSlider>
        </CarouselWrapper>
      </CarouselProvider>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: rgba(196, 196, 196, 0.2);
  padding: 5.1rem 0;

  > h3 {
    color: #3c3c3c;
    font-size: 1.35rem;
    margin: 0 auto 2.05em;
    padding: 0 2em;
    text-align: center;
  }

  > p {
    color: #3c3c3c;
    font-size: 1rem;
    line-height: 1.4em;
    margin: 0 auto 2.55em;
    max-width: 32.813em;
    padding: 0 1.25em;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    > h3 {
      font-size: 1.5rem;
    }

    > p {
      font-size: 1.25rem;
      max-width: 58.15em;
    }
  }
`;

const CarouselWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  max-width: 90rem;
  width: 100%;
`;

const StlyedSlider = styled(Slider)`
  grid-column: 2/12;
  grid-row: 1;
  margin: 0 auto;
  max-width: 23.375rem;
  width: 100%;

  @media screen and (min-width: 48rem) {
    max-width: 46.75rem;
  }

  @media screen and (min-width: 74rem) {
    max-width: 73.875rem;
    left: 0.188rem;
    position: relative;
  }
`;

const StyledSlide = styled(Slide)`
  margin: 0 0.625rem;
  max-width: 23.375rem;
  width: 100%;

  p {
    color: ${({ color }) => color};
    font-size: 1.25rem;
    font-weight: 700;

    &:first-of-type {
      margin-top: 1.1em;
    }

    &:last-of-type {
      color: #3c3c3c;
      font-weight: 400;
    }
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  background-color: transparent;
  cursor: pointer;
  grid-column: 1/2;
  grid-row: 1;

  @media screen and (min-width: 520px) {
    grid-column: 2/3;
  }

  @media screen and (min-width: 696px) {
    grid-column: 3/4;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1/2;
  }

  @media screen and (min-width: 1050px) {
    grid-column: 2/3;
  }

  @media screen and (min-width: 1200px) {
    grid-column: 1/2;
  }

  @media screen and (min-width: 1440px) {
    left: 3.313rem;
    position: relative;
  }
`;

const StyledButtonNext = styled(ButtonNext)`
  background-color: transparent;
  cursor: pointer;
  grid-column: 12/13;
  grid-row: 1;

  @media screen and (min-width: 520px) {
    grid-column: 11/12;
  }

  @media screen and (min-width: 696px) {
    grid-column: 10/11;
  }

  @media screen and (min-width: 768px) {
    grid-column: 12/13;
  }

  @media screen and (min-width: 1050px) {
    grid-column: 11/12;
  }

  @media screen and (min-width: 1200px) {
    position: relative;
  }

  @media screen and (min-width: 1440px) {
    left: 4.563rem;
    position: relative;
  }
`;

export default EventCarousel;
