import * as React from 'react';

function SvgBgMoon2(props) {
  return (
    <svg viewBox="0 0 243 243" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M105.155 209.56c-.677 1.954-1.45 4.186-2.406 6.043 45.945 9.045 92.772-16.927 108.536-62.416 17.311-49.955-9.231-104.166-58.911-121.382-47.446-16.442-98.91 6.673-118.689 52.021 4.101.796 8.105 1.871 12.013 3.225 50.055 17.034 76.865 72.276 59.457 122.509z"
        fill="#36A9E1"
      />
    </svg>
  );
}

export default SvgBgMoon2;
