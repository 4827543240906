import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Markup } from 'interweave';
import { useWindowSize } from 'hooks';
import { scrollToAnchor } from 'utils';
import {
  Svg11,
  Svg21,
  Svg22,
  Svg23,
  Svg31,
  Svg32,
  Svg33,
  Svg34,
  Svg35,
  Homepage
} from '../../assets/svgs/intro-graphics';

const getSVG = (dayAndEventId) => {
  switch (dayAndEventId) {
    case '1_1':
      return <Svg11 />;
    case '2_1':
      return <Svg21 />;
    case '2_2':
      return <Svg22 />;
    case '2_3':
      return <Svg23 />;
    case '3_1':
      return <Svg31 />;
    case '3_2':
      return <Svg32 />;
    case '3_3':
      return <Svg33 />;
    case '3_4':
      return <Svg34 />;
    case '3_5':
      return <Svg35 />;
    default:
      return <Homepage />;
  }
};

const Intro = ({ text, colors, did, eid, padding }) => {
  const { windowWidth } = useWindowSize();
  return (
    <Wrapper id="info" eid={eid} padding={padding}>
      <Text colors={colors}>
        <Markup content={text} noWrap />
        <Button
          onClick={() => {
            scrollToAnchor('agenda', {
              duration: 1200,
              offset: eid ? -180 : -90
            });
          }}
          whileHover={{ scale: 1.025 }}
          whileTap={{ scale: 0.975 }}>
          View Agenda
        </Button>
      </Text>
      {windowWidth >= 1000 && getSVG(`${did}_${eid}`)}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  align-items: center;
  display: grid;
  grid-column-gap: 0.875rem;
  grid-template-columns: repeat(12, 1fr);
  justify-items: center;
  margin: 0 auto;
  max-width: 1903px;
  padding: 4rem 0;

  svg {
    /* eid will be undefined on the Homepage */
    grid-column: ${({ eid }) => (!eid || eid === '1' ? '7/13' : '8/12')};
  }

  @media screen and (min-width: 1000px) {
    padding: ${({ padding }) => padding || '5.875rem 0 7.813rem'};
  }

  @media screen and (min-width: 1900px) {
    svg {
      justify-self: ${({ eid }) => (!eid || eid === '1' ? 'start' : 'centre')};
      max-width: ${({ eid }) => (!eid || eid === '1' ? '945px' : '625px')};
      grid-column: ${({ eid }) => (!eid || eid === '1' ? '7/13' : '7/12')};
    }
  }
`;

const Text = styled.div`
  grid-column: 2/12;
  max-width: 400px;
  width: 100%;

  li {
    margin-left: 1.25em;
  }

  h2 {
    color: #3c3c3c;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.286em;
    margin-bottom: 0.25em;
  }

  p,
  ul {
    font-size: 1rem;
    line-height: 1.6em;
    margin-bottom: 2em;
  }

  button {
    background-color: ${({ colors }) => colors.secondary};
    height: 3rem;
    width: 10.875rem;
  }

  @media screen and (min-width: 550px) {
    max-width: 548px;

    h2 {
      font-size: 2.3rem;
    }
  }

  @media screen and (min-width: 1000px) {
    grid-column: 2/7;

    h2 {
      font-size: 2.625rem;
      margin-bottom: 0.143em;
    }

    p {
      margin-right: 1em;
    }
  }
`;

export default Intro;
