import * as React from 'react';

function SvgNextArrow(props) {
  return (
    <svg viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.071 1.929L9.142 9l-7.07 7.071" stroke="#0095FF" strokeWidth={4} />
    </svg>
  );
}

export default SvgNextArrow;
