import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { EventButton } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FirebaseContext, LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';
import { format } from 'date-fns';
import { fullGridContainer, breakpoints } from 'styles';
import { wrap } from '@popmotion/popcorn';
import { LeftArrow, RightArrow } from 'assets/svgs';

export default (props) => {
  const {
    colors,
    eventName,
    eventBanner,
    eventBannerPromotion,
    eventHighlights,
    eventBannerVideo
  } = props;
  const promoImage = getImage(eventBannerPromotion);
  const { headerRef } = useContext(LocalContext);
  const headerImage = getImage(eventBanner);
  const [current, setCurrent] = useState();
  const [currentStream, setCurrentStream] = useState();

  const { windowWidth } = useWindowSize();
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const firstThumb = wrap(0, eventHighlights.length, page);
  const secondThumb = wrap(0, eventHighlights.length, page + 1);
  const thirdThumb = wrap(0, eventHighlights.length, page + 2);

  const thumbnail1 = getImage(eventHighlights[firstThumb].thumb);
  const thumbnail2 = getImage(eventHighlights[secondThumb].thumb);
  const thumbnail3 = getImage(eventHighlights[thirdThumb].thumb);

  useEffect(() => {
    setCurrentStream(eventHighlights[0].url);
    setCurrent(eventHighlights[0].id);
  }, []);

  const handleThumbClick = (stream) => {
    setCurrentStream(stream.url);
    setCurrent(stream.id);
  };

  return (
    <Header ref={headerRef} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
      <Background>
        {eventBannerVideo !== '' && (
          <BackgroundVideo autoPlay muted id="bgvid">
            <source src={eventBannerVideo} type="video/mp4" />
          </BackgroundVideo>
        )}
        <BackgroundImage image={headerImage} alt="Background" />
      </Background>
      <Container id="livestream" colors={colors}>
        <LiveStream>
          <div style={{ padding: '56.25% 0 0 0', height: '100%', position: 'relative' }}>
            <iframe
              title={eventName}
              src={currentStream}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
            />
          </div>
        </LiveStream>

        {eventHighlights.length >= 2 && (
          <Thumbs>
            <Prev colors={colors} onClick={() => paginate(-1)}>
              <LeftArrow />
            </Prev>
            <Thumb onClick={() => handleThumbClick(eventHighlights[firstThumb])}>
              <ImageContainer>
                <Overlay active={eventHighlights[firstThumb].id === current}>Now Playing</Overlay>

                <GatsbyImage image={thumbnail1} alt={eventHighlights[firstThumb].title} />
              </ImageContainer>
              <ThumbTitle colors={colors}>{eventHighlights[firstThumb].title}</ThumbTitle>
              <ThumbDescription colors={colors}>
                <Markup content={eventHighlights[firstThumb].description} />
              </ThumbDescription>
            </Thumb>
            {windowWidth >= 768 && (
              <>
                <Thumb onClick={() => handleThumbClick(eventHighlights[secondThumb])}>
                  <ImageContainer>
                    <Overlay active={eventHighlights[secondThumb].id === current}>
                      Now Playing
                    </Overlay>
                    <GatsbyImage image={thumbnail2} alt={eventHighlights[secondThumb].title} />
                  </ImageContainer>
                  <ThumbTitle colors={colors}>{eventHighlights[secondThumb].title}</ThumbTitle>
                  <ThumbDescription colors={colors}>
                    <Markup content={eventHighlights[secondThumb].description} />
                  </ThumbDescription>
                </Thumb>
                <Thumb onClick={() => handleThumbClick(eventHighlights[thirdThumb])}>
                  <ImageContainer>
                    <Overlay active={eventHighlights[thirdThumb].id === current}>
                      Now Playing
                    </Overlay>

                    <GatsbyImage image={thumbnail3} alt={eventHighlights[thirdThumb].title} />
                  </ImageContainer>

                  <ThumbTitle colors={colors}>{eventHighlights[thirdThumb].title}</ThumbTitle>
                  <ThumbDescription colors={colors}>
                    <Markup content={eventHighlights[thirdThumb].description} />
                  </ThumbDescription>
                </Thumb>
              </>
            )}
            <Next colors={colors} onClick={() => paginate(1)}>
              <RightArrow />
            </Next>
          </Thumbs>
        )}
      </Container>
      {eventBannerPromotion && <BannerPromotion image={promoImage} alt="Promotion" />}
    </Header>
  );
};

const Prev = styled.div`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  border: none;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  justify-self: end;
  left: 0.5rem;
  opacity: 0.8;
  outline: none;
  padding-left: 0.425rem;
  position: absolute;
  top: 7.5rem;
  transform: translateY(-50%);
  transition: 0.5s;
  width: 1.75rem;
  @media (min-width: 768px) {
    left: 2rem;
    top: 8rem;
  }
  @media (min-width: 1024px) {
    width: 2rem;
    height: 2rem;
  }
  svg {
    path {
      stroke: rgb(245, 250, 250);
    }
  }
  :hover {
    opacity: 1;
  }
`;
const Next = styled.div`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  border: none;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  justify-self: start;
  opacity: 0.8;
  outline: none;
  padding-right: 0.625rem;
  position: absolute;
  right: 0.5rem;
  top: 7.5rem;
  transform: translateY(-50%);
  transition: 0.5s;
  width: 1.75rem;

  @media (min-width: 768px) {
    top: 8rem;
    right: 2rem;
  }

  @media (min-width: 1024px) {
    width: 2rem;
    height: 2rem;
  }

  svg {
    path {
      stroke: rgb(245, 250, 250);
    }
  }
  :hover {
    opacity: 1;
  }
`;

const ThumbDescription = styled.p`
  color: #3c3c3c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5em;
  text-align: left !important;
  a {
    color: ${({ colors }) => colors.secondary};
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 1rem;
  }
`;

const ThumbTitle = styled.h3`
  color: ${({ colors }) => colors.secondary};
  font-size: 0.975rem;
  font-weight: 600;
  line-height: 1.5em;
  text-align: left !important;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 1.25rem;
  }
`;

const Overlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: ${({ active }) => (active ? 1 : 0)};
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: ${({ active }) => (active ? 2 : -1)};
`;

const ImageContainer = styled.div`
  height: 100%;
  margin-bottom: 1.5rem;
  max-height: 150px;
  position: relative;
  transition: 0.5s;
  width: 100%;
  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 150px;
    opacity: 0.75;
    transition: 0.5s;
    width: 100%;
    img {
      object-position: center;
    }
  }
`;

const Thumb = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  outline: none;
  position: relative;
  transition: 0.5s;
  width: 100%;
  &:hover {
    outline: none;
    border: none;
    .gatsby-image-wrapper {
      opacity: 1;
    }
  }
  &:focus {
    border: none;
    outline: none;
  }
`;

const Thumbs = styled.div`
  align-items: start;
  background: rgba(255,255,255,.9);
  display: grid;
  grid-column: 1/7;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  justify-content: center;
  padding: 3rem;
  position: relative;
  @media (min-width: 768px) {
    grid-column: 1/7;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 3rem 5rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const LiveStream = styled.div`
  grid-column: 1/7;
  height: auto;
  position: relative;
  .videolink {
    height: 100% !important;
    padding-bottom: 56.25%;
    position: relative;
    width: 100% !important;
    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-column: 2/12;
    height: 100%;
    margin: 0px;
  }
`;

const Container = styled.div`
  ${fullGridContainer}
  color: #00004E;
  grid-gap: 0;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0;
  position: relative;
  @media (min-width: ${breakpoints.lg}) {
    grid-gap: 0;
  }
`;

const BannerPromotion = styled(GatsbyImage)`
  height: auto;
  width: 100%;
  z-index: 1;
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 0;
`;

const BackgroundVideo = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Header = styled.header`
  height: auto;
  padding-top:4rem;
  position: relative;
  width: 100%;
  @media (min-width: ${breakpoints.lg}){
    min-height: 100vh;
    padding: 1.25rem 1.25rem 4rem;
    padding-top: 10rem;
  }
`;
