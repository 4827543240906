import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  NewUserEmailVerification,
  LoggingExistingUserIn,
  RecoverEmail,
  ActionCodeError,
  LoginModal,
  Unsubscribe
} from 'components';
import LogRocket from 'logrocket';
import { FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { clearQueryParams } from 'utils';
import { pfizer } from 'styles';

// LogRocket.init('i05pci/itransform');

export default () => {
  const [userEmail, setUserEmail] = useState('');
  const [emailRecovered, setEmailRecovered] = useState(false);
  const [newUserEmailVerified, setNewUserEmailVerified] = useState(false);
  const [showNewUserEmailVerificationModal, setShowNewUserEmailVerificationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoggingExistingUserInModal, setShowLoggingExistingUserInModal] = useState(false);
  const [showRecoverEmailModal, setShowRecoverEmailModal] = useState(false);
  const [actionCodeError, setActionCodeError] = useState({ mode: null, message: null });
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const { loading, firebase } = useContext(FirebaseContext);
  const {
    mode,
    oobCode: actionCode,
    continueUrl,
    unsubscribe
  } = queryString.parse(useLocation().search);
  const colors = pfizer;

  useEffect(() => {
    if (unsubscribe) {
      setShowUnsubscribeModal(true);
    }
  }, [unsubscribe]);

  useEffect(() => {
    if (!loading && firebase) {
      const existingUserLocalStorageData = JSON.parse(
        window.localStorage.getItem('existingUseriTransform2022')
      );
      const newUserLocalStorageData = JSON.parse(
        window.localStorage.getItem('newUseriTransform2022')
      );
      const referringUrl = JSON.parse(
        window.localStorage.getItem('referringUrliTransform2022')
      )?.data;
      switch (mode) {
        case 'signIn':
          if (existingUserLocalStorageData?.email || newUserLocalStorageData?.email) {
            firebase.auth
              .signInWithEmailLink(
                existingUserLocalStorageData?.email || newUserLocalStorageData?.email,
                window.location.href
              )
              .then((result) => {
                const { isNewUser } = result.additionalUserInfo;
                if (isNewUser) {
                  if (newUserLocalStorageData) {
                    setShowNewUserEmailVerificationModal(true);
                  }
                  // LogRocket.identify(result.user.uid, {
                  //   email: newUserLocalStorageData.email,
                  //   uid: result.user.uid
                  // });
                  firebase
                    .createUserInDatabase({
                      newUserLocalStorageData,
                      referringUrl,
                      uid: result.user.uid
                    })
                    .then(() => {
                      firebase?.auth?.currentUser?.reload().then(() => {
                        setNewUserEmailVerified(true);
                        window.localStorage.removeItem('newUseriTransform2022');
                        window.localStorage.removeItem('referringUrliTransform2022');
                        clearQueryParams();
                        firebase.sendWelcomeEmail({
                          name: newUserLocalStorageData.name,
                          email: newUserLocalStorageData.email,
                          day2Franchise: newUserLocalStorageData.day2Franchise,
                          day3Franchise: newUserLocalStorageData.day3Franchise,
                          colors
                        });
                      });
                    })
                    .catch(async (error) => {
                      console.error(error);

                      const userFromAuth = firebase?.auth?.currentUser;
                      const { data: userExists } = await firebase.checkIfUserAlreadyExists({
                        email: newUserLocalStorageData.email?.toLowerCase()
                      });

                      if (userFromAuth && !userExists) {
                        console.error(
                          "User exists in auth but not in database. There's been an error, so deleting from Auth."
                        );
                        await userFromAuth.delete();
                        console.error('User from Auth deleted.');
                        setActionCodeError({
                          mode,
                          message:
                            'Error while creating account. Please fill out the registation form again. If you continue to see this message please contact support@itransform2022.com'
                        });
                      }

                      setShowNewUserEmailVerificationModal(false);
                      clearQueryParams();
                    });
                } else if (!isNewUser) {
                  if (existingUserLocalStorageData) {
                    setShowLoggingExistingUserInModal(true);
                  }
                  // LogRocket.identify(result.user.uid, {
                  //   email: existingUserLocalStorageData.email,
                  //   uid: result.user.uid
                  // });
                  window.localStorage.removeItem('existingUseriTransform2022');
                  if (newUserLocalStorageData) {
                    window.localStorage.removeItem('newUseriTransform2022');
                    window.localStorage.removeItem('referringUrliTransform2022');
                  }
                  // clearQueryParams();
                }
              })
              .catch((error) => {
                console.error(error);

                LogRocket.identify('ERROR - NO USER ID', {
                  email: existingUserLocalStorageData.email
                });

                if (showNewUserEmailVerificationModal) {
                  setShowNewUserEmailVerificationModal(false);
                }

                if (showLoggingExistingUserInModal) {
                  setShowLoggingExistingUserInModal(false);
                }

                if (existingUserLocalStorageData) {
                  window.localStorage.removeItem('existingUseriTransform2022');
                }

                if (newUserLocalStorageData) {
                  window.localStorage.removeItem('newUseriTransform2022');
                  window.localStorage.removeItem('referringUrliTransform2022');
                }

                clearQueryParams();

                switch (error.code) {
                  case 'auth/invalid-action-code':
                    setActionCodeError({
                      mode,
                      message: 'Invalid or expired link.'
                    });
                    break;
                  default:
                    setActionCodeError({
                      mode,
                      message: error.message
                    });
                    break;
                }
              });
          } else if (!firebase?.auth?.currentUser) {
            // TODO: This needs a rethink! Works for logins, but not registrations.
            // User registered or tried to log in on another device/browser
            setShowLoginModal(true);
          } else if (firebase?.auth?.currentUser) {
            clearQueryParams();
          }
          break;
        case 'recoverEmail':
          setShowRecoverEmailModal(true);
          firebase
            .checkActionCode(actionCode)
            .then(({ data }) => {
              const recoveredEmail = data.email;
              return firebase
                .applyActionCode(actionCode)
                .then(() => firebase.updateEmailInDatabase(recoveredEmail))
                .then(() => {
                  setEmailRecovered(true);
                  setUserEmail(recoveredEmail);
                });
            })
            .catch((error) => {
              console.error(error);
              setShowRecoverEmailModal(false);
              switch (error.code) {
                case 'auth/invalid-action-code':
                  setActionCodeError({
                    mode,
                    message: 'Invalid or expired link.<br />Please try again.'
                  });
                  break;
                default:
                  setActionCodeError({
                    mode,
                    message: error.message
                  });
                  break;
              }
            });
          break;
        default:
          break;
      }
    }
  }, [loading, firebase]);

  return (
    <>
      <Modal
        hideCloseButton
        openWithoutTrigger={showUnsubscribeModal}
        modalContent={<Unsubscribe />}
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showNewUserEmailVerificationModal}
        modalContent={
          <NewUserEmailVerification
            setShowNewUserEmailVerificationModal={setShowNewUserEmailVerificationModal}
            newUserEmailVerified={newUserEmailVerified}
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showLoggingExistingUserInModal}
        modalContent={
          <LoggingExistingUserIn
            colors={colors}
            setShowLoggingExistingUserInModal={setShowLoggingExistingUserInModal}
            continueUrl={continueUrl}
          />
        }
      />
      <Modal
        hideCloseButton
        // disableCloseOnClickOutside
        openWithoutTrigger={showLoginModal}
        redirectIfNotAuthorised
        modalContent={
          <LoginModal
            colors={colors}
            setShowLoginModal={setShowLoginModal}
            lastUserInteractionWasFromAnotherDeviceOrBrowser
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showRecoverEmailModal}
        modalContent={
          <RecoverEmail
            colors={colors}
            emailRecovered={emailRecovered}
            setShowRecoverEmailModal={setShowRecoverEmailModal}
            userEmail={userEmail}
          />
        }
      />
      <Modal
        hideCloseButton
        openWithoutTrigger={actionCodeError.message}
        modalContent={
          <ActionCodeError
            colors={colors}
            actionCodeError={actionCodeError}
            setActionCodeError={setActionCodeError}
          />
        }
      />
    </>
  );
};
