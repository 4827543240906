import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import {
  Logo,
  PfizerOncologyLogo,
  PfizerHospitalLogo,
  DefaultCommenterAvatar,
  Hub
} from 'assets/svgs';
import { Modal, RegistrationForm, LoginModal, UserProfile, NavbarDropdown } from 'components';
// AccessibilityBar
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { useWindowSize } from 'hooks';
import { fullGridContainer, breakpoints, pfizer } from 'styles';
// import Eye from 'assets/svgs/eye-icon.svg';
import { motion } from 'framer-motion';

const Navbar = ({ colors }) => {
  const { theme, mobile } = useContext(LocalContext);
  const { pathname } = useLocation();
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [navOpen, setNavOpen] = useState(false);
  const { windowWidth } = useWindowSize();
  // const [themeToggleOpen, setThemeToggleOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);
  let isMounted = true;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => setActiveLink(pathname), [pathname]);

  useEffect(() => {
    const listener = window.addEventListener('scroll', () => {
      setIsScrolled(window.scrollY >= 50);
    });
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const toggleNav = () => {
    // setThemeToggleOpen(false);
    setNavOpen(!navOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  // const toggleTheme = () => {
  //   setNavOpen(false);
  //   setThemeToggleOpen(!themeToggleOpen);
  // };

  const handleLogout = () =>
    firebase
      .logout()
      .then(() => {
        document.querySelector('html').classList.remove('scroll-lock');
        navigate('/');
      })
      .catch((error) => isMounted && console.error(error.message));

  return (
    <>
      <StyledNavbar
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        isScrolled={isScrolled}
        // themeToggle={themeToggleOpen}
        theme={theme}>
        <LogoContainer
          to="/"
          aria-label="Home"
          onClick={() => {
            toggleActiveLink('welcome');
          }}>
          <Logo />
        </LogoContainer>
        {(navOpen || !mobile) && (
          <NavLinks
            initial={mobile ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.75 }}>
            {/* Display link if verified user */}
            {/* {!user?.emailVerified && (
              <></>
            )} */}
            {/* {user && (
              <NavLink
                to="/events"
                theme={theme}
                className={activeLink === '/events' ? 'active' : null}
                onClick={() => {
                  toggleActiveLink('/events');
                }}>
                <Hub />
                Hub
              </NavLink>
            )} */}
            {/* <NavLink
              theme={theme}
              to="#gallery"
              className={activeLink === 'gallery' ? 'active ' : null}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(galleryRef);
                toggleActiveLink('gallery');
              }}>
              Gallery
            </NavLink> */}
            {/* <NavLink
              theme={theme}
              to="#faq"
              className={activeLink === 'faq' ? 'active ' : null}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(faqRef);
                toggleActiveLink('faq');
              }}>
              FAQ
            </NavLink>
            <NavLink
              theme={theme}
              to="#contact"
              className={activeLink === 'contact' ? 'active ' : null}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(contactRef);
                toggleActiveLink('contact');
              }}>
              Contact
            </NavLink> */}
            {mobile && !loading && firebase?.auth?.currentUser && user && (
              <LoginButton theme={theme}>
                <Modal
                  trigger={
                    <>
                      <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                        {user.name.split(' ')[0]}
                      </p>
                      {user.avatarUrl ? (
                        <ProfileImage src={user.avatarUrl} alt={user.fullName} theme={theme} />
                      ) : (
                        <DefaultCommenterAvatar />
                      )}
                    </>
                  }
                  modalContent={<UserProfile handleLogout={handleLogout} />}
                  colors={colors}
                />
              </LoginButton>
            )}
            {mobile && !loading && !firebase?.auth?.currentUser && !user && (
              <>
                <RegisterButton theme={theme}>
                  <Modal
                    trigger={<p>Register</p>}
                    modalContent={<RegistrationForm colors={colors} />}
                    // extraFields={
                    //   pathname === '/' ? ['companyOrInstitutionName', 'linkedInProfile'] : []
                    // }
                    colors={colors}
                  />
                </RegisterButton>

                <LoginButton theme={theme}>
                  <Modal
                    trigger={
                      <>
                        <p>Login </p>
                        <DefaultCommenterAvatar />
                      </>
                    }
                    modalContent={<LoginModal colors={colors} />}
                    colors={colors}
                  />
                </LoginButton>
              </>
            )}
            {user && (
              <HomeLink
                to="/"
                theme={theme}
                className={activeLink === '/' ? 'active' : null}
                onClick={() => {
                  toggleActiveLink('/');
                }}>
                <Hub />
                Home
              </HomeLink>
            )}
            {user && <NavbarDropdown toggleNav={toggleNav} />}
          </NavLinks>
        )}
        {!mobile && !loading && firebase?.auth?.currentUser && user && (
          <User theme={theme}>
            <Modal
              hideCloseButton
              trigger={
                <>
                  <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                    {user.name.split(' ')[0]}
                  </p>
                  {user.avatarUrl ? (
                    <ProfileImage src={user.avatarUrl} alt={user.fullName} theme={theme} />
                  ) : (
                    <DefaultCommenterAvatar />
                  )}
                </>
              }
              modalContent={<UserProfile handleLogout={handleLogout} />}
              colors={colors}
            />
          </User>
        )}
        {!mobile && !loading && !firebase?.auth?.currentUser && !user && (
          <User theme={theme}>
            <Modal
              trigger={
                <>
                  {' '}
                  <p style={{ marginRight: '.625rem', fontWeight: '700' }}>Login</p>
                  <DefaultCommenterAvatar />
                </>
              }
              hideCloseButton
              transparentCloseButtonBackground
              modalContent={<LoginModal colors={colors} />}
              colors={colors}
              style={{
                right: '25px',
                top: '24px'
              }}
            />
          </User>
        )}
        {/* <ThemeToggle onClick={toggleTheme} open={themeToggleOpen} theme={theme}>
          {user && (
            <BulletPoint
              top=".5rem"
              right="1rem"
              width="11rem"
              content={`
                      <p>Accessibility Bar</p>`}
            />
          )}
          <Eye />
          {themeToggleOpen && <AccessibilityBar />}
        </ThemeToggle> */}
        <NavToggler onClick={toggleNav} theme={theme} aria-label="Menu">
          <NavBurger theme={theme} navOpen={navOpen}>
            <BurgerBar theme={theme} navOpen={navOpen} />
            <BurgerBar theme={theme} navOpen={navOpen} />
            <BurgerBar theme={theme} navOpen={navOpen} />
          </NavBurger>
        </NavToggler>
      </StyledNavbar>
    </>
  );
};

const ProfileImage = styled.img`
  background: #c4c4c4;
  border: 0.125rem solid #0095ff;
  border-radius: 50%;
  height: 1.625rem;
  object-fit: cover;
  width: 1.625rem;
`;

const User = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  grid-area: user;
  grid-column: 10/12;
  height: 100%;
  outline: 0;
  width: 100%;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.textColor};
    font-size: 1rem;
    font-family: noto-sans, tahoma, sans-serif;
    font-weight: 400;
  }
  @media (min-width: ${breakpoints.lg}) {
    div {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      align-items: center;

      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }
  svg {
    max-width: 1.5rem;
    circle {
      stroke: ${({ theme }) => theme.textColor};
    }
    path {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;

const RegisterButton = styled.div`
  background: white;
  border: none;
  color: #3c3c3c;
  cursor: pointer;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  width: 100%;
  @media (min-width: 1150px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`;

const LoginButton = styled.button`
  background: ${pfizer.secondary};
  border: none;
  cursor: pointer;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  max-width: 100%;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  width: 100%;
  div {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    margin-left: 0.5rem;
    width: 1.25rem;
    path {
      fill: white;
    }
    circle {
      stroke: white;
    }
  }
  @media (min-width: 1150px) {
    background: transparent;
    justify-content: flex-end;
    font-size: 0.875rem;
    line-height: 1.5rem;
    svg {
      width: 1.5rem;
    }
  }
`;

const NavLink = styled(Link)`
  align-items: center;
  background: white;
  color: #3c3c3c;
  cursor: pointer;
  display: flex;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 3rem;
  padding-top: 0.125rem;
  text-align: center;
  width: 100%;
  svg {
    margin-right: 0.5rem;
    margin-top: -0.25rem;
    width: 0.75rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 0.875rem;
    line-height: 1.5rem;

    width: auto;
    background: transparent;
    svg {
      margin-top: 0;
    }
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  background: white;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  grid-area: links;
  grid-gap: 0px;
  height: auto;
  justify-content: center;
  @media (min-width: ${breakpoints.lg}) {
    /* grid-column: 3/5; */
    background: transparent;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    justify-content: flex-start;
    height: 100%;
    margin-top: 0px;
    box-shadow: none;
    width: 400px;
    position: absolute;
    left: 6.25rem;
    /* left: -2.45rem; */
  }
`;

const HomeLink = styled(NavLink)`
  svg {
    margin-top: 0;
  }
  @media screen and (min-width: 1150px) {
    margin-bottom: 0.125rem;
    margin-left: 1.75rem;

    svg {
      margin-top: -2px;
    }
  }
`;

const NavBurger = styled.div`
  height: 1rem;
  position: relative;
  width: ${({ navOpen }) => (navOpen === true ? '1rem' : '1.25rem')};
`;

const BurgerBar = styled.span`
  background: white;
  content: '';
  display: block;
  height: 0.185rem;
  left: 0;
  position: absolute;
  transform-origin: center center;
  width: 1.25rem;
  &:first-child {
    top: ${({ navOpen }) => (navOpen === true ? '50%' : '0')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(45deg)' : 'none')};
  }
  &:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    opacity: ${({ navOpen }) => (navOpen === true ? '0' : '1')};
  }
  &:last-child {
    bottom: ${({ navOpen }) => (navOpen === true ? 'initial' : '0')};
    top: ${({ navOpen }) => (navOpen === true ? '50%' : 'initial')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(-45deg)' : 'none')};
  }
`;

const NavToggler = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.bg};
  cursor: pointer;
  display: flex;
  grid-area: toggle;
  height: 4rem;
  justify-content: center;
  justify-self: end;
  width: 4rem;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const LogoContainer = styled(Link)`
  grid-area: logo;
  svg {
    height: 2.5rem;
    margin-left: 1.25rem;
    object-fit: cover;
    width: auto;
    z-index: 2;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-column: 2/3;

    svg {
      margin-left: unset;
    }
  }
`;

const StyledNavbar = styled(motion.nav)`
  align-items: center;
  background: #fff;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  display: grid;
  grid-gap: 0px;
  grid-template-areas:
    'logo toggle'
    'links links';
  grid-template-columns: 1fr 60px;
  height: 4rem;
  left: 0;
  max-width: 100vw;
  padding: 0px;
  position: fixed;
  top: 0;
  transition-duration: 0.5s;
  width: 100%;
  z-index: 5;

  @media (min-width: ${breakpoints.lg}) {
    background: 'white;';
    box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.15);
    box-shadow: none;
    grid-gap: 1.25rem;
    grid-template-areas: 'logo links user';
    grid-template-columns: repeat(12, 1fr);
    height: 5rem;
  }
`;

export default Navbar;

// const ThemeToggle = styled.div`
//   cursor: pointer;
//   position: relative;
//   width: 3.75rem;
//   height: 3.75rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   grid-area: eye;
//   justify-self: end;
//   background: ${({ open, theme }) => (open ? theme.bgLight : 'transparent')};
//   svg {
//     path {
//       stroke: ${({ theme }) => theme.textColor};
//       :last-child {
//         fill: ${({ theme }) => theme.textColor};
//       }
//     }
//   }
//   @media (min-width: ${breakpoints.lg}) {
//     grid-column: 12/13;
//     justify-self: start;
//     width: 4rem;
//     height: 4rem;
//   }
// `;
