import * as React from 'react';

function SvgVirus3(props) {
  return (
    <svg viewBox="0 0 65 68" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M59.231 45.903c-3.693-5.148 2.34-9.48 1.022-14.74-.987-3.896-2.506-6.143-6.609-6.967-4.732-.953-4.706-8.005-8.25-10.88-5.483-4.47-14.09 2.995-16.247-8.004-2.07-10.485-15.374-2.798-20.08.634-.166.12-2.366 1.373-2.4 2.712-.088 2.574.14 4.804-1.275 7.052-1.249 2.008-3.632 3.595-4.584 5.706-1.571 3.483-.68 6.169 1.205 9.438.873 1.578 2.62 2.728 3.99 3.852 3.867 3.132 3.169 8.177 2.933 12.458a10.994 10.994 0 001.277 5.908 11.238 11.238 0 004.223 4.388c4.776 2.711 10.651-.558 12.851 6.864.541 1.853 2.113 3.852 4.365 3.664 5.046-.37 8.486-2.883 13.052-4.574 4.915-1.819 10.433 4.205 15.461 1.828 9.255-4.402 3.091-13.796-.934-19.339z"
        fill="#04AEEE"
      />
    </svg>
  );
}

export default SvgVirus3;
