import * as React from 'react';

function SvgTarget2(props) {
  return (
    <svg viewBox="0 0 167 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M146.915 58.934a68.24 68.24 0 01-38.19 88.524 68.249 68.249 0 01-88.524-38.182 68.258 68.258 0 0138.19-88.49 68.24 68.24 0 0188.524 38.148zM22.361 108.416a65.919 65.919 0 0085.512 36.892 65.946 65.946 0 0036.891-85.512 65.936 65.936 0 00-85.52-36.891 65.926 65.926 0 00-36.883 85.511z"
        fill="url(#target-2_svg__paint0_linear_746_4)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.85 2.053a1.154 1.154 0 00-.086-.387 1.144 1.144 0 00-1.119-.732 82.905 82.905 0 00-27.804 5.852A82.405 82.405 0 006.226 53.42a1.17 1.17 0 00.654 1.506 1.17 1.17 0 001.505-.654A80.134 80.134 0 0153.702 8.937a80.728 80.728 0 0127.03-5.688 1.162 1.162 0 001.118-1.196zm43.001 10.129c.065.16.093.333.082.506a1.152 1.152 0 01-.842 1.041 1.171 1.171 0 01-.884-.101 81.24 81.24 0 00-23.785-8.82 1.162 1.162 0 01-.861-1.37 1.162 1.162 0 011.36-.911 83.547 83.547 0 0124.465 9.078c.215.135.379.338.465.577zM166.69 80.25a83.09 83.09 0 00-5.808-26.867 83.27 83.27 0 00-13.511-22.7 1.17 1.17 0 00-1.635-.147 1.173 1.173 0 00-.146 1.635 80.828 80.828 0 0113.132 22.073 80.532 80.532 0 015.653 26.109 1.165 1.165 0 001.214 1.11 1.162 1.162 0 001.101-1.213zm-1.497 19c.081.208.102.435.06.654a83.638 83.638 0 01-18.863 38.724 1.173 1.173 0 01-1.635.121 1.173 1.173 0 01-.111-1.644 81.181 81.181 0 0018.329-37.683 1.165 1.165 0 011.368-.912 1.162 1.162 0 01.852.74zm-31.625 50.307a1.163 1.163 0 01-.387 1.36 83.634 83.634 0 01-18.932 10.507 83.487 83.487 0 01-20.274 5.241 1.17 1.17 0 01-.859-.235 1.149 1.149 0 01-.44-.772 1.173 1.173 0 011.006-1.3 80.716 80.716 0 0019.706-5.094 80.94 80.94 0 0018.382-10.215c.121-.091.26-.156.408-.193a1.138 1.138 0 01.876.13c.13.078.243.182.333.304a.913.913 0 01.181.267zM3.352 72.497c.07.186.094.387.068.585a80.994 80.994 0 004.974 40.884 80.159 80.159 0 0018.425 27.795 1.172 1.172 0 010 1.644 1.16 1.16 0 01-.818.34 1.16 1.16 0 01-.818-.34 82.486 82.486 0 01-18.932-28.604A83.249 83.249 0 011.14 72.746a1.162 1.162 0 011.308-.99 1.162 1.162 0 01.904.74zm63.593 91.965a1.133 1.133 0 00-.051-.679 1.158 1.158 0 00-.844-.723A81.52 81.52 0 0151 158.129a1.145 1.145 0 00-1.523.594 1.16 1.16 0 00.593 1.531 83.852 83.852 0 0015.49 5.069 1.154 1.154 0 001.385-.861z"
        fill="url(#target-2_svg__paint1_linear_746_4)"
      />
      <defs>
        <linearGradient
          id="target-2_svg__paint0_linear_746_4"
          x1={83.567}
          y1={15.986}
          x2={83.567}
          y2={152.252}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD00CE" />
          <stop offset={0.536} stopColor="#FF4937" />
          <stop offset={0.99} stopColor="#FFBE38" />
        </linearGradient>
        <linearGradient
          id="target-2_svg__paint1_linear_746_4"
          x1={127.372}
          y1={23.403}
          x2={58.176}
          y2={165.353}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD00CE" />
          <stop offset={0.484} stopColor="#FF4937" />
          <stop offset={0.979} stopColor="#FFBE38" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTarget2;
