import React, { useContext, useState, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Modal, LoginModal } from 'components';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { Markup } from 'interweave';
import { scrollToAnchor, includesSurname, hexToRGB } from 'utils';
import { motion, AnimatePresence } from 'framer-motion';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../FixRequiredSelect';

import { FormContainer, FormInput, FormInputLabel, FormChecks, FormTerms } from '../FormComponents';

const resetForm = () => ({
  name: '',
  email: '',
  region: '',
  country: '',
  function: '',
  day2Franchise: '',
  day3Franchise: '',
  termsAndConditions: false,
  referringUrl:
    typeof window !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('referringUrliTransform2022'))?.data
});

const FormSelect = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />
);

const regionOptions = [
  {
    value: 'Emerging Markets',
    label: 'Emerging Markets'
  },
  {
    value: 'North America',
    label: 'North America'
  },
  {
    value: 'IDM',
    label: 'IDM'
  },
  {
    value: 'Global',
    label: 'Global'
  }
];

const countryOptions = [
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'American Samoa', label: 'American Samoa' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Anguilla', label: 'Anguilla' },
  { value: 'Antarctica', label: 'Antarctica' },
  { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Aruba', label: 'Aruba' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas (the)', label: 'Bahamas (the)' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bermuda', label: 'Bermuda' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bonaire, Sint Eustatius and Saba', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Bouvet Island', label: 'Bouvet Island' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'British Indian Ocean Territory (the)', label: 'British Indian Ocean Territory (the)' },
  { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cabo Verde', label: 'Cabo Verde' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Cayman Islands (the)', label: 'Cayman Islands (the)' },
  { value: 'Central African Republic (the)', label: 'Central African Republic (the)' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Christmas Island', label: 'Christmas Island' },
  { value: 'Cocos (Keeling) Islands (the)', label: 'Cocos (Keeling) Islands (the)' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros (the)', label: 'Comoros (the)' },
  {
    value: 'Congo (the Democratic Republic of the)',
    label: 'Congo (the Democratic Republic of the)'
  },
  { value: 'Cook Islands (the)', label: 'Cook Islands (the)' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Curaçao', label: 'Curaçao' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czechia', label: 'Czechia' },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic (the)', label: 'Dominican Republic (the)' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Eswatini', label: 'Eswatini' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Falkland Islands (the)', label: 'Falkland Islands (the)' },
  { value: 'Faroe Islands (the)', label: 'Faroe Islands (the)' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'French Guiana', label: 'French Guiana' },
  { value: 'French Polynesia', label: 'French Polynesia' },
  { value: 'French Southern Territories (the)', label: 'French Southern Territories (the)' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia (the)', label: 'Gambia (the)' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Gibraltar', label: 'Gibraltar' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Greenland', label: 'Greenland' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guadeloupe', label: 'Guadeloupe' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guernsey', label: 'Guernsey' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Heard Island and McDonald Islands', label: 'Heard Island and McDonald Islands' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Isle of Man', label: 'Isle of Man' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jersey', label: 'Jersey' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macao', label: 'Macao' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands (the)', label: 'Marshall Islands (the)' },
  { value: 'Martinique', label: 'Martinique' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mayotte', label: 'Mayotte' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New Caledonia', label: 'New Caledonia' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger (the)', label: 'Niger (the)' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Niue', label: 'Niue' },
  { value: 'Norfolk Island', label: 'Norfolk Island' },
  { value: 'Northern Mariana Islands (the)', label: 'Northern Mariana Islands (the)' },
  { value: 'North Korea', label: 'North Korea' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Palestine', label: 'Palestine' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines (the)', label: 'Philippines (the)' },
  { value: 'Pitcairn Islands', label: 'Pitcairn Islands' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'North Macedonia', label: 'North Macedonia' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Réunion', label: 'Réunion' },
  { value: 'Saint Barthélemy', label: 'Saint Barthélemy' },
  {
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    label: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', label: 'Saint Lucia' },
  { value: 'Saint Martin (French part)', label: 'Saint Martin (French part)' },
  { value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
  { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Sint Maarten (Dutch part)', label: 'Sint Maarten (Dutch part)' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  {
    value: 'South Georgia and the South Sandwich Islands',
    label: 'South Georgia and the South Sandwich Islands'
  },
  { value: 'South Korea', label: 'South Korea' },
  { value: 'South Sudan', label: 'South Sudan' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Timor-Leste', label: 'Timor-Leste' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tokelau', label: 'Tokelau' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Turks and Caicos Islands (the)', label: 'Turks and Caicos Islands (the)' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'United States of America', label: 'United States of America' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Virgin Islands (British)', label: 'Virgin Islands (British)' },
  { value: 'Virgin Islands (U.S.)', label: 'Virgin Islands (U.S.)' },
  { value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
  { value: 'Western Sahara', label: 'Western Sahara' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' }
];

const functionOptions = [
  {
    value: 'Commercial',
    label: 'Commercial'
  },
  {
    value: 'Medical',
    label: 'Medical'
  },
  {
    value: 'Enabling Functions',
    label: 'Enabling Functions'
  }
];

const day2FranchiseOptions = [
  {
    value: 'Hematology session',
    label: 'Hematology session'
  },
  {
    value: 'Breast session',
    label: 'Breast session'
  },
  {
    value: 'Not planning to attend franchise session',
    label: 'Not planning to attend franchise session'
  }
];

const day3FranchiseOptions = [
  {
    value: 'Breast & Lung session',
    label: 'Breast & Lung session'
  },
  {
    value: 'Biosimilars session',
    label: 'Biosimilars session'
  },
  {
    value: 'GU session',
    label: 'GU session'
  },
  {
    value: 'Not planning to attend franchise session',
    label: 'Not planning to attend franchise session'
  }
];

const customDropdownMenuStyles = (colors) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused ? '0.25em solid #fff' : '1px solid #fff',
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    height: '2.5rem',
    letterSpacing: '0.036em',
    padding: '0'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: state.isFocused ? '0 0 0 0.85rem' : '0 0 0 1rem',
    transition: 'none',
    cursor: 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    '&:hover': {
      color: '#fff'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
    margin: 0
  }),
  menu: (provided) => ({
    ...provided,
    background: colors.primary,
    border: '0.25em solid #fff',
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.25em',
    letterSpacing: '0.036em',
    margin: '0',
    width: 'calc(100% + 0.5em)'
  }),
  menuList: (provided) => ({
    ...provided,
    '::-webkit-scrollbar': {
      width: '11px'
    },
    '::-webkit-scrollbar-track': {
      background: '#ededed'
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.secondary,
      height: '1.25rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.tertiary
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#fff'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '0.85rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Noto Sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: 'white',
    margin: 0
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
    margin: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
});

const RegistrationForm = ({ event, colors, contrast }) => {
  const { firebase } = useContext(FirebaseContext);
  const { setANewUserHasRegisteredForAnEvent } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(resetForm());
  const [errorMessage, setErrorMessage] = useState('');
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [disableSubmitButtonWhileCheckingEmailAddress, setDisableSubmitButtonWhileCheckingInputs] =
    useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 750;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!includesSurname(name)) {
          setErrorMessage('Please include a surname');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  useEffect(() => {
    const { email } = formValues;
    if (email) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(async () => {
        try {
          const { data: _userAlreadyExists } = await firebase.checkIfUserAlreadyExists({ email });
          if (_userAlreadyExists) {
            setUserAlreadyExists(true);
            throw new Error(
              "This email address already belongs to an iTransform 2022 account. Click 'Login' above if it belongs to you."
            );
          } else if (userAlreadyExists) {
            setUserAlreadyExists(false);
            setErrorMessage('');
          }
        } catch (error) {
          setErrorMessage(error.message);
        } finally {
          setDisableSubmitButtonWhileCheckingInputs(false);
        }
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (errorMessage && !userAlreadyExists) {
      setErrorMessage('');
    }
    if (!submissionStatus.submitted) {
      setFormValues((currentValues) => ({
        ...currentValues,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (errorMessage || submissionStatus.submitting) {
      return;
    }

    /* Can't destructure 'function' below as it obviously clashes with the JavaScript 'function' object, so renaming it _function */
    const {
      name,
      email,
      region,
      country,
      function: _function,
      day2Franchise,
      day3Franchise,
      termsAndConditions
    } = formValues;

    if (!name) {
      setErrorMessage('Please include your name');
      return;
    }

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!email) {
      setErrorMessage('Please include an email address');
      return;
    }

    if (userAlreadyExists) {
      setErrorMessage(
        "This email address already belongs to an iTransform 2022 account. Click 'Login' above if it belongs to you."
      );
      return;
    }

    if (!region) {
      setErrorMessage('Please select your region');
      return;
    }

    if (!country) {
      setErrorMessage('Please select your country');
      return;
    }

    if (!_function) {
      setErrorMessage('Please select your function');
      return;
    }

    if (!day2Franchise) {
      setErrorMessage('Please select a franchise session for Day 2');
      return;
    }

    if (!day3Franchise) {
      setErrorMessage('Please select a franchise session for Day 3');
      return;
    }

    // if (!termsAndConditions) {
    //   setErrorMessage('You must agree to our Terms & Conditions to register');
    //   return;
    // }

    try {
      setSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: true
      };

      const { data } = await firebase.sendSignInWithMagicLinkEmail({
        firstName: name.split(' ')[0],
        email,
        colors,
        actionCodeSettings
      });

      if (data.Message === 'OK') {
        setSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(
          'newUseriTransform2022',
          JSON.stringify({
            ...formValues,
            actionCodeSettings
          })
        );
        setANewUserHasRegisteredForAnEvent(true);
      } else {
        setSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm());
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <StyledFormContainer
      id="registration-form"
      onSubmit={(e) => handleSubmit(e)}
      colors={colors}
      contrast={contrast}>
      <div
        style={{
          fontSize: '1.75rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
          marginBottom: '0.55rem',
          textAlign: 'center'
        }}>
        Register Here
      </div>
      <div
        style={{
          color: `#fff`,
          display: 'flex',
          fontSize: '0.875rem',
          justifyContent: 'center',
          marginBottom: '2.6em'
        }}>
        Already have an account?&nbsp;
        <Modal
          trigger={
            <span
              style={{
                fontSize: '0.875rem',
                textAlign: 'center',
                marginBottom: '1.5em',
                textDecoration: 'underline'
              }}>
              Click here!
            </span>
          }
          hideCloseButton
          modalContent={<LoginModal colors={colors} />}
        />
      </div>
      <div
        style={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr'
        }}>
        <div
          style={{
            gridRow: 1,
            gridColumn: 1
          }}
          variants={fadeInAndOutVariants()}
          initial="initial"
          animate="animate"
          exit="exit">
          <Fields>
            <FormInput
              id="name"
              name="name"
              onChange={handleInputChange}
              placeholder="Full Name*"
              type="text"
              value={formValues.name}
              style={{
                fontSize: '0.875rem'
              }}
              required
            />
            <FormInput
              id="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Email*"
              type="email"
              value={formValues.email}
              style={{
                fontSize: '0.875rem'
              }}
              required
            />
            <FormSelect
              id="region"
              name="region"
              required
              defaultValue={formValues.region}
              value={regionOptions.filter(({ value }) => value === formValues.region)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  region: value
                }));
              }}
              placeholder="Region*"
              styles={customDropdownMenuStyles(colors)}
              options={regionOptions}
            />
            <FormSelect
              id="country"
              name="country"
              required
              defaultValue={formValues.country}
              value={countryOptions.filter(({ value }) => value === formValues.country)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  country: value
                }));
              }}
              placeholder="Country*"
              styles={customDropdownMenuStyles(colors)}
              options={countryOptions}
            />
            <FormSelect
              id="function"
              name="function"
              required
              defaultValue={formValues.function}
              value={functionOptions.filter(({ value }) => value === formValues.function)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  function: value
                }));
              }}
              placeholder="Function*"
              styles={customDropdownMenuStyles(colors)}
              options={functionOptions}
            />
            <p style={{ fontSize: '0.75rem', color: colors.secondary, fontWeight: 'bold' }}>
              The event will include parallel Franchise sessions on Day 2 & 3, please select the
              session you plan to attend on each day:
            </p>
            <FormSelect
              id="day2Franchise"
              name="day2Franchise"
              required
              defaultValue={formValues.day2Franchise}
              value={day2FranchiseOptions.filter(({ value }) => value === formValues.day2Franchise)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  day2Franchise: value
                }));
              }}
              placeholder="Day 2 Franchise*"
              styles={customDropdownMenuStyles(colors)}
              options={day2FranchiseOptions}
            />
            <FormSelect
              id="day3Franchise"
              name="day3Franchise"
              required
              defaultValue={formValues.day3Franchise}
              value={day3FranchiseOptions.filter(({ value }) => value === formValues.day3Franchise)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  day3Franchise: value
                }));
              }}
              placeholder="Day 3 Franchise*"
              styles={customDropdownMenuStyles(colors)}
              options={day3FranchiseOptions}
            />
          </Fields>
          <ErrorMessage
            errorMessage={errorMessage}
            style={{ color: '#F8981D', position: 'relative', top: '0.5rem' }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2.5rem'
            }}>
            {submissionStatus.submitted ? (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  color: '#F8981D',
                  width: '90%'
                }}>
                Check your inbox to verify your email address and complete your registration.
                Can&apos;t find our email? Be sure to check your junk!
              </div>
            ) : (
              <Button
                type="submit"
                width="7.5rem"
                disabled={
                  userAlreadyExists || disableSubmitButtonWhileCheckingEmailAddress || errorMessage
                }
                whileTap={{
                  scale: 0.95
                }}
                whileHover={{
                  scale: 1.05
                }}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                {
                  <AnimatePresence>
                    {submissionStatus.submitting && (
                      <LoadingSpinner
                        style={{ width: '2rem', color: '#fff', position: 'absolute' }}
                      />
                    )}
                  </AnimatePresence>
                }
                {
                  <AnimatePresence>
                    {!submissionStatus.submitting && (
                      <motion.span
                        variants={fadeInAndOutVariants()}
                        initial="initial"
                        animate="animate"
                        exit="exit">
                        Submit
                      </motion.span>
                    )}
                  </AnimatePresence>
                }
              </Button>
            )}
          </div>
        </div>
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};

  @media screen and (min-width: 1150px) {
    background: ${({ colors }) => hexToRGB({ color: colors.primary, alpha: 0.85 })};
    padding: 2.2rem 1.25rem 2.5rem;
    max-width: 28.25rem;
  }

  input {
    margin-bottom: 0;
  }

  button {
    background: ${({ colors }) => colors.secondary};
  }
`;

const Fields = styled.div`
  display: grid;
  grid-row-gap: 1.25rem;
  grid-template-columns: 1fr;
  margin-bottom: 1.25rem;
`;

const FormCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0.75rem;
  margin-top: 0.3rem;
  outline: none;
  padding: 0.625rem;
  position: relative;
  transition: background-color 0.2s;
  width: 1.5rem;

  &:focus {
    border: 0.19rem solid #fff;
    margin-right: 0.575rem;
  }

  &:focus:checked {
    background-color: ${({ colors }) => `${colors.secondary}`};
    border: 0.19rem solid #fff;
    margin-right: 0.575rem;
  }

  &:checked:before {
    color: ${({ contrast }) => (contrast ? `#000` : `#fff`)};
    content: '✓';
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37.5%, -67.5%);
    width: 1rem;
  }
`;

export default RegistrationForm;
