import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  Virus1,
  Virus2,
  Virus3,
  Virus4,
  Virus5,
  Target1,
  Target2,
  Target3,
  Target4,
  Target5
} from '../../assets/svgs/hero-graphics';

function generateRandomDelay(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function generateRandomAngle(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const HomepageBannerLoggedInGraphic = () => (
  <Wrapper>
    <StyledVirus1 width={60} />
    <StyledVirus2 width={63} />
    <StyledVirus3 width={65} />
    <StyledVirus4 width={60} />
    <StyledVirus5 width={75} />
    <StyledTarget1 width={130} />
    <StyledTarget2 width={151} />
    <StyledTarget3 width={167} />
    <StyledTarget4 width={128} />
    <StyledTarget5 width={132} />
  </Wrapper>
);

const Wrapper = styled.div`
  flex-shrink: 0;
  height: 593px;
  margin-left: 9rem;
  margin-top: 4rem;
  position: relative;
  width: 300px;

  svg {
    position: absolute;
  }
`;

const virusAnimation = (angle) => keyframes`
  0% {
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 0, 0deg);
  }

  50% {
    transform: scale3d(1.125, 1.125, 1.125) rotate3d(1, 1, 1, ${angle}deg);
  }

  100% {
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 0, 0deg);
  }
`;

const StyledVirus1 = styled(Virus1)`
  animation: ${virusAnimation(generateRandomAngle(-20, 20))} 1.5s ease-in-out infinite;
  animation-delay: ${generateRandomDelay(0, 0.5)}s;
  left: 100px;
  top: 30px;
`;

const StyledVirus2 = styled(Virus2)`
  animation: ${virusAnimation(generateRandomAngle(-20, 20))} 1.5s ease-in-out infinite;
  animation-delay: ${generateRandomDelay(0, 0.5)}s;
  right: 50px;
  top: 170px;
`;

const StyledVirus3 = styled(Virus3)`
  animation: ${virusAnimation(generateRandomAngle(-20, 20))} 1.5s ease-in-out infinite;
  animation-delay: ${generateRandomDelay(0, 0.5)}s;
  left: 50px;
  top: 290px;
`;

const StyledVirus4 = styled(Virus4)`
  animation: ${virusAnimation(generateRandomAngle(-20, 20))} 1.5s ease-in-out infinite;
  animation-delay: ${generateRandomDelay(0, 0.5)}s;
  bottom: 143px;
  right: 48px;
`;

const StyledVirus5 = styled(Virus5)`
  animation: ${virusAnimation(generateRandomAngle(-20, 20))} 1.5s ease-in-out infinite;
  animation-delay: ${generateRandomDelay(0, 0.5)}s;
  bottom: 40px;
  left: 91px;
`;

const targetAnimation = () => keyframes`
  0% {
    transform: translate3d(1000px, 0, 0) scale3d(8, 8, 8) rotate3d(0, 0, 1, -720deg);
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  90% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }

  95% {
    transform: translate3d(0, 0, 0) scale3d(1.175, 1.175, 1.175) rotate3d(0, 0, 0, 0deg);
  }

  100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }
`;

const StyledTarget1 = styled(Target1)`
  animation: ${targetAnimation} 1.25s ease-in forwards;
  animation-delay: 0;
  left: 65px;
  opacity: 0;
  perspective: 1000px;
  top: -6px;
`;

const StyledTarget2 = styled(Target2)`
  animation: ${targetAnimation} 1.25s ease-in forwards;
  animation-delay: 1.5s;
  opacity: 0;
  perspective: 1000px;
  right: 6px;
  top: 121px;
`;

const StyledTarget3 = styled(Target3)`
  animation: ${targetAnimation} 1.25s ease-in forwards;
  animation-delay: 1s;
  bottom: 181px;
  opacity: 0;
  perspective: 1000px;
`;

const StyledTarget4 = styled(Target4)`
  animation: ${targetAnimation} 1.25s ease-in forwards;
  animation-delay: 2s;
  bottom: 108px;
  opacity: 0;
  perspective: 1000px;
  right: 14px;
`;

const StyledTarget5 = styled(Target5)`
  animation: ${targetAnimation} 1.25s ease-in forwards;
  animation-delay: 0.5s;
  bottom: -10px;
  left: 63px;
  opacity: 0;
  perspective: 1000px;
`;

export default HomepageBannerLoggedInGraphic;
