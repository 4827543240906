import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { RegistrationForm, Button, EventButton, Modal, LoadingSpinner } from 'components';
import { FormLabel } from 'components/Forms/FormComponents';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FirebaseContext, LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { pfizer } from 'styles';
import { Markup } from 'interweave';

export default (props) => {
  const { event } = props;
  const { loading, firebase, user } = useContext(FirebaseContext);
  const { headerRef } = useContext(LocalContext);
  const { windowWidth } = useWindowSize();
  const [isRegistering, setIsRegistering] = useState(false);

  const handleRegisterForEventIfUserAlreadyHasAnAccount = async () => {
    setIsRegistering(true);
    const { name, slug, start, end, location } = event;

    firebase
      .registerForEventIfUserAlreadyHasAnAccount({
        uid: user.uid,
        eid: event.eid
      })
      .then(({ data: _user }) => {
        // const { eventsUserCanAccess } = _user;
        // if (eventsUserCanAccess.includes(event.eid)) {
        //   firebase.sendEventWelcomeEmail({
        //     attendeeName: user.fullName,
        //     attendeeEmail: user.email,
        //     event: {
        //       name,
        //       slug,
        //       start,
        //       end,
        //       location
        //     }
        //   });
        // } else {
        //   firebase.sendAwaitingAccessApprovalEmail({
        //     fullName: _user.fullName,
        //     email: _user.email,
        //     uid: _user.uid,
        //     eventName: event.name
        //   })
        // }
        setIsRegistering(false);
      })
      .catch((error) => {
        console.error(error);
        setIsRegistering(false);
      });
  };

  return (
    <Header
      colors={event.colors}
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Background
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.25 }}>
        {event.bannerVideo ? (
          <BackgroundVideo autoPlay muted id="bgvid">
            <source src={event.bannerVideo} type="video/mp4" />
          </BackgroundVideo>
        ) : (
          <BackgroundImage image={getImage(event.banner)} alt="Background" />
        )}
        <BackgroundOverlay colors={event.colors} />
      </Background>
      <Container>
        <Content>
          {event.logo && (
            <AnimatedLogo
              initial={{ transform: 'rotateX(90deg)' }}
              animate={{ transform: 'rotateX(0deg)' }}
              transition={{ duration: 1, delay: 1 }}>
              <GatsbyImage image={getImage(event.logo)} alt="logo" />
            </AnimatedLogo>
          )}
          <Date
            colors={event.colors}
            contrast={event.bannerContrast}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <Markup content={event.dates} />
          </Date>
          <Title
            colors={event.colors}
            contrast={event.bannerContrast}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            {<Markup content={event.title} />}
            {event.subtitle && (
              <Subtitle colors={event.colors} contrast={event.bannerContrast}>
                <Markup content={event.subtitle} />
              </Subtitle>
            )}
          </Title>
          {event.description && (
            <Description
              colors={event.colors}
              contrast={event.bannerContrast}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              {event.description}
            </Description>
          )}
          {event.eventDepartmentLogo && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <EventLogo image={getImage(event.eventDepartmentLogo)} alt="Event Department Logo" />
            </motion.div>
          )}
          {!loading &&
            firebase &&
            user &&
            !user.eventsUserWantsToAccess.includes(event.eid) &&
            !user.eventsUserHasBeenDeniedAccessTo.includes(event.eid) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}>
                <Button
                  onClick={() => handleRegisterForEventIfUserAlreadyHasAnAccount()}
                  style={{
                    backgroundColor: pfizer.tertiary,
                    width: '180px',
                    marginBottom: '1.75rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  {isRegistering ? (
                    <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
                  ) : (
                    'Register'
                  )}
                </Button>
              </motion.div>
            )}
          <AnimatedDateButton
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <EventButton
              colors={event.colors}
              eventTitle={event.title}
              description={event.description}
              startTime={event.startTime}
              endTime={event.endTime}
            />
          </AnimatedDateButton>
        </Content>
        {!loading &&
          firebase &&
          user &&
          !user.emailDomainIsWhitelisted &&
          (user.eventsUserWantsToAccess.includes(event.eid) ||
            user.eventsUserHasBeenDeniedAccessTo.includes(event.eid)) && (
            <FormContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <AwaitingApprovalMessage colors={pfizer}>
                <FormLabel>Approval Pending</FormLabel>
                <p>
                  {`Our moderators will review your registration application and grant you access to
                  the ${
                    user.eventsUserWantsToAccess.includes(event.eid) ? 'event' : 'site'
                  } as soon as possible.`}
                </p>
                <p>Keep an eye on that inbox!</p>
              </AwaitingApprovalMessage>
            </FormContainer>
          )}
      </Container>
    </Header>
  );
};

const AwaitingApprovalMessage = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  margin-top: 6.625em;
  padding: 1.25em 0.5em 0.5em;
  text-align: center;

  h3 {
    font-size: 1.75rem;
    margin-bottom: 0.625em;
  }

  p {
    margin-bottom: 0.75em;
  }
`;

const RegisterButton = styled(motion.div)`
  margin-left: 0.5rem;
  margin-top: 1rem;
  button {
    background-color: ${({ colors }) => colors.tertiary};
  }
`;

const FormContainer = styled(motion.div)`
  grid-column: 1/7;
  height: auto;
  position: relative;
  z-index: 2;

  @media (min-width: 1150px) {
    grid-column: 8 /12;
  }
`;

const AnimatedDateButton = styled(motion.div)`
  height: auto;
  width: auto;
`;

const EventLogo = styled(GatsbyImage)`
  margin: 1rem 0 1.5rem;
  width: 12.5rem;
`;

const Description = styled(motion.div)`
  color: white;
  font-size: 1.25rem;
  line-height: 1.3em;
  margin-bottom: 1.5rem;
  @media (min-width: 1150px) {
    margin-bottom: 1rem;
  }
`;

const Title = styled(motion.h1)`
  color: ${({ contrast, colors }) => (contrast ? 'white' : colors.secondary)};
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  @media (min-width: 1150px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.div`
  color: ${({ contrast, colors }) => (contrast ? 'white' : colors.tertiary)};
  font-size: 0.65em;
  font-weight: 700;
  margin: 1.375rem 0 0.25rem;
`;

const Date = styled(motion.h4)`
  color: ${({ contrast, colors }) => (contrast ? 'white' : colors.tertiary)};
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const AnimatedLogo = styled(motion.div)`
  height: auto;
  transform: rotateX(-90deg);
  transform-style: preserve-3d;
  width: auto;
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
    width: 15rem;
  }
  @media (min-width: 1150px) {
    .gatsby-image-wrapper {
      width: 30rem;
    }
  }
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: flex-start;
  padding: 2rem 0.5rem;
  position: relative;
  width: 100%;
  z-index: 2;
  @media (min-width: 768px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/8;
    padding: 0;
    padding-right: 3rem;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
`;

const BackgroundVideo = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const BackgroundOverlay = styled.div`
  background-image: ${({ colors }) =>
    `linear-gradient(to right, ${colors.primary}, rgba(0,0,0,0))`};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Background = styled(motion.div)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Container = styled.div`
  color: var(--black);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 0 auto 1rem;
  max-width: 1440px;
  padding: 5rem 0.625rem 2.5rem;
  position: relative;
  width: 100%;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 6rem 0 12.5rem;
  }
  @media (min-width: 1150px) {
    padding: 12.5rem 0 5rem;
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Header = styled.header`
  background-color: ${({ colors }) => colors.primary};
  height: auto;
  position: relative;
  width: 100%;
  @media (min-width: 1150px) {
    min-height: 100vh;
  }
`;
