import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import { breakpoints, gridContainer } from 'styles';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'components';
import { Plus } from 'assets/svgs';
// import { LeftArrow, RightArrow, FbIcon, TwitterIcon, LinkedinIcon, EmailIcon } from 'assets/svgs';
import Interweave from 'interweave';

export default ({ kioskEntry }) => (
  <Backdrop>
    <Container>
      <BannerImg image={getImage(kioskEntry.banner)} alt={kioskEntry.title} />
      <div>
        <Title color="#3c3c3c">Oncology GLocal POA meeting 2022</Title>
        <Title color={kioskEntry.color}>{kioskEntry.title}</Title>
        <SubTitle>Pre-reads and additional information</SubTitle>
        <Buttons>
          {kioskEntry.calendarLink && (
            <a
              href={kioskEntry.calendarLink}
              target="_blank"
              rel="noreferrer"
              style={{ display: 'block', margin: '1.438em 0 0.5em' }}>
              <Button
                style={{
                  backgroundColor: kioskEntry.color,
                  fontSize: '0.75rem',
                  height: '2.35em',
                  lineHeight: '1.362em',
                  padding: '1em',
                  maxWidth: '14.5em',
                  width: '100%'
                }}
                whileHover={{
                  scale: 1.0225
                }}
                whileTap={{
                  scale: 0.975
                }}>
                <Plus width="0.75rem" style={{ marginRight: '0.75em' }} />
                Add To Calendar
              </Button>
            </a>
          )}
        </Buttons>
        <Excerpt>
          <Interweave content={kioskEntry.excerpt} noWrap />
        </Excerpt>
        <Content color={kioskEntry.color}>
          <Interweave content={kioskEntry.content} allowElements />
        </Content>
        {kioskEntry.videos && (
          <KioskEntryVideos>
            {kioskEntry.videos.map((video, i) => (
              <KioskVideo key={i}>
                <KioskVideoThumbnail image={getImage(video.thumbnail)} alt={video.name} />
                <Details>
                  <h3>{video.title}</h3>
                  <ReadMore $bgColor={kioskEntry.color} href={video.url} target="_blank">
                    View Video
                  </ReadMore>
                </Details>
              </KioskVideo>
            ))}
          </KioskEntryVideos>
        )}
      </div>
    </Container>
  </Backdrop>
);

const KioskEntryVideos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.875rem auto 0;
  width: 100%;
`;

const KioskVideo = styled.div`
  align-items: flex-start;
  background: #fff;
  box-shadow: 0 0.375rem 1.25rem rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0.625rem 1.25rem;
  max-width: 28.313rem;
  text-align: left;
  width: 100%;
`;

const KioskVideoThumbnail = styled(GatsbyImage)`
  flex-shrink: 0;
`;

const Buttons = styled.div`
  margin-bottom: 3.3rem;
`;

const ReadMore = styled.a`
  align-items: center;
  background: ${({ $bgColor }) => $bgColor};
  border-radius: 3.125rem;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  margin-bottom: 0;
  text-transform: uppercase;
  transition: 0.5s;
  width: 10rem;
  &:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1.25rem 1.75rem;
  width: 100%;

  h3 {
    color: #0095ff;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1.65rem;
  }
`;

const Content = styled.div`
  height: auto;
  padding: 0;
  width: 100%;
  h3 {
    color: #3c3c3c;
    margin-bottom: 0.75em;
  }
  p {
    margin-bottom: 1.45rem;
    color: #3c3c3c;
    line-height: 1.5em;
    max-width: 57.875rem;
  }

  ul {
    left: 1.1rem;
    position: relative;
  }

  a {
    align-items: center;
    background: ${({ color }) => color};
    border-radius: 3.125rem;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 2.5rem;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    transition: 0.5s;
    button {
      background: transparent;
      color: white;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    &:hover {
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
    }
  }
  .video-embed {
    margin: 5rem auto;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    iframe {
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }
`;

const Excerpt = styled.h4`
  color: #3c3c3c;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.75rem;
  max-width: 100%;
  text-align: left;
`;

const Title = styled.h2`
  color: ${({ color }) => color};
  font-size: 2rem;
  max-width: 100%;
  text-align: left;
`;

const SubTitle = styled.p`
  color: #3c3c3c;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 1.8rem;
  max-width: 46.3rem;
`;

const BannerImg = styled(GatsbyImage)`
  height: 15.625rem;
  width: 100%;
  @media (min-width: 48rem) {
    height: 26.25rem;
  }
`;

const Container = styled(motion.div)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  padding-top: 1rem;
  width: 1200px;
  > div {
    background: #ededed;
    padding: 1rem 1.5rem;
    width: 100%;
  }
  @media (min-width: 48rem) {
    > div {
      padding: 3rem 5rem 1.5rem;
    }
  }
  @media (min-width: 71.875rem) {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 71.875rem) {
    overflow-x: hidden;
  }
`;
