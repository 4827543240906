import React from 'react';
import { EventSpeaker, IndividualSpeaker } from 'components';
import styled from 'styled-components';

export default ({ eventSpeakers, colors }) => (
  <Speakers>
    {eventSpeakers?.map((speaker) => (
      <EventSpeaker
        key={speaker.speakerOrder}
        data={speaker}
        buttonText="Read Bio"
        cropped
        popupType="speaker"
        colors={colors}
        carouselData={eventSpeakers}
      />
    ))}
  </Speakers>
);

const Speakers = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.25rem;
  position: relative;
  width: 100%;
  @media (min-width: 1600px) {
    max-width: 1400px;
  }
`;
