import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Button } from 'components';

const boxData = [
  {
    title: 'Day 1',
    body: 'We want your help to Play for Patient Impact - where we will raise money for our patient charities with a fun and competitive team game.',
    action: 'View DAY 1 Livestream',
    primary: '#BD00CE',
    secondary: 'rgba(189, 0, 206, 0.2)',
    link: '/day-1/'
  },
  {
    title: 'Day 2',
    body: 'We will meet Berfu Yaziyurt our new Oncology IDM Regional President and hear from our very own Truly Matters Band.',
    action: 'View DAY 2 Livestream',
    primary: '#FD5A45',
    secondary: 'rgba(253, 90, 69, 0.2)',
    link: '/day-2/'
  },
  {
    title: 'Day 3',
    body: 'We will celebrate the contributions of our vaccine colleagues, reflect on their road travelled and the exciting future pipeline ahead of all of us within oncology.',
    action: 'View DAY 3 Livestream',
    primary: '#479244',
    secondary: 'rgba(71, 146, 68, 0.2)',
    link: '/day-3/'
  }
];

const EmbracingJoy = () => (
  <Wrapper>
    <Segment>
      <SegmentHeading>Embracing Joy everyday!</SegmentHeading>
      <SegmentSubHeading>
        We have imbued our POA with JOY and each day has a fun session.
      </SegmentSubHeading>
      <Grid>
        {boxData.map((obj, i) => (
          <GridElement key={i} style={{ background: `${obj.secondary}` }} to={obj.link}>
            <div style={{ background: `${obj.primary}` }}>{obj.title}</div>
            <p>{obj.body}</p>
            <span style={{ color: `${obj.primary}` }}>{obj.action}</span>
          </GridElement>
        ))}
      </Grid>
      <SegmentSubHeading>For more info visit</SegmentSubHeading>
      <Button
        type="button"
        width="296px"
        whileTap={{
          scale: 0.975
        }}
        whileHover={{
          scale: 1.025
        }}
        style={{
          alignItems: 'center',
          alignSelf: 'center',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative'
        }}>
        <a
          href="http://awards.pfizer.com/EnterpriseRecognitionPortal/English/_layouts/15/Pfizer.ECF.Awards.Website/ERPHome.aspx"
          target="_blank"
          rel="noreferrer"
          style={{ color: '#FFF' }}>
          Moments of Joy Portal
        </a>
      </Button>
    </Segment>
    <Segment>
      <SegmentHeading>How to get the most from your experience</SegmentHeading>
      <InstructionsBody>
        <div>
          You will see we have a simple interactive webchat facility running alongside each
          streaming event. Please try out the chat function, comment on the presentations, ask
          questions of our presenters, give emoji feedback as you go and have lots of fun.
          <br />
          <br />
          Some of the sessions will include polling, others will include game play and others still
          will be just for you to relax, reflect and enjoy the presentations of your team.
          <br />
          <br />
          The chat functions are visible to all, the Q&A function is usable by all, and your
          questions are visible only by the event moderator. Our promise is to respond to all
          questions submitted either in show or post the event, so please send them in.
        </div>
        <div>
          Also you will see plenty of pre-read support material and you can move between the events
          each day.
          <br />
          <br />
          And just in case you miss any parts, all presentations will be available after the live
          stream ends.
          <br />
          <br />
          <strong>Begin right away - we would love to hear from you</strong>
          <br />
          The Chat and Q&A are already open so go right ahead ask any questions you have within the
          portal or share your Tough Questions with us at our dedicated email:{' '}
          <a href="mailto:OncologyGlocalPoAMeeting@pfizer.com">
            OncologyGlocalPoAMeeting@pfizer.com
          </a>
          <br />
          <br />
          We would love to hear from you.
          <br />
          <br />
          <strong>Most of all EnJoy the iTransform 2022 conference</strong>
          <p>The GLocal POA Planning Team</p>
        </div>
      </InstructionsBody>
    </Segment>
  </Wrapper>
);

const Wrapper = styled.section`
  align-items: center;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Segment = styled.div`
  align-items: center;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  width: 100%;
  @media screen and (min-width: 768px) {
    max-width: 72.875rem;
    margin: 3rem;
  }
`;

const SegmentHeading = styled.h3`
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.375rem;
  margin: 1rem;
  @media screen and (min-width: 768px) {
    font-size: 2.625rem;
  }
`;

const SegmentSubHeading = styled.h4`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 13px;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.625rem 1rem;
  width: 100%;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 2.625rem 0;
  }
`;

const InstructionsBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  div {
    width: 100%;
    p {
      padding: 1.5rem 0;
      text-align: right;
    }
    a {
      color: #0095ff;
      text-decoration: underline;
      &:hover {
        color: #1200c9;
      }
    }
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin: 0.625rem;
    padding: 3.125rem 0 0;
    div {
      margin: 0.625rem;
    }
  }
`;

const GridElement = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.25rem 0;
  padding: 3.35rem 2rem 1rem 2rem;
  position: relative;
  text-align: center;
  width: 100%;
  div {
    align-items: center;
    border-radius: 1.875rem;
    bottom: 90%;
    color: #fff;
    display: flex;
    height: 3rem;
    justify-content: center;
    left: calc(50% - 3.75rem);
    position: absolute;
    width: 7.5rem;
  }
  p {
    color: #3c3c3c;
    padding-bottom: 2rem;
  }
  span {
    font-weight: 700;
    justify-self: end;
    margin-top: auto;
    text-transform: uppercase;
  }
  @media screen and (min-width: 768px) {
    flex-direction: column;
    margin: 1.25rem;
  }
`;

export default EmbracingJoy;
