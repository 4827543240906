import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Add } from 'assets/svgs';
import ICalendarLink from 'react-icalendar-link';

export default ({ colors, contrast, transparent, eventTitle, description, startTime, endTime }) => {
  const event = {
    title: eventTitle.replace('<br />', ' '),
    description: description || eventTitle,
    location: 'Virtual Conference',
    startTime: `${startTime}`,
    endTime: `${endTime}`
  };
  return (
    <StyledDateButton width="250px" colors={colors} transparent={transparent} contrast={contrast}>
      <AddIcon colors={colors} />
      <ICalendarLink
        style={{
          height: '3rem',
          background: 'transparent',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '-30px',
          position: 'relative'
        }}
        event={event}>
        Add To Calendar
      </ICalendarLink>
    </StyledDateButton>
  );
};

const StyledDateButton = styled.div`
  align-items: center;
  background: transparent;
  border-radius: 30px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  left: -0.5rem;
  padding: 1rem 1rem 1rem 0;
  position: relative;
  width: 280px;
  z-index: 2;
  a {
    align-items: center;
    background: white;
    color: ${({ colors, contrast }) => (contrast ? colors.tertiary : colors.secondary)};
    cursor: pointer;
    display: flex;
    font-family: noto-sans, tahoma, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    justify-content: flex-start;
    list-style: none;
    margin: 0.5rem 0;
    text-transform: uppercase;
    transition: 0.5s;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
  svg {
    circle {
      fill: ${({ contrast, colors }) => (contrast ? colors.tertiary : colors.secondary)};
    }
    path {
      stroke: #fff;
    }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const AddIcon = styled(Add)`
  height: 3rem;
  margin: 0 !important;
  width: 1.5rem;
`;
