const firebaseConfig = {
  apiKey: "AIzaSyC0be8ao-MZ4jSRtIcmamqJNP5S22Opego",
  authDomain: "itransform-a888a.firebaseapp.com",
  databaseURL: "https://itransform-a888a-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "itransform-a888a",
  storageBucket: "itransform-a888a.appspot.com",
  messagingSenderId: "1022999495346",
  appId: "1:1022999495346:web:d7e93b081be59bf6723fab"
};

export default firebaseConfig;
