import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Button, ErrorMessage, LoadingSpinner, Notification } from 'components';
import { DefaultAvatar } from 'assets/animated-svgs';
import { LocalContext, FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowSize } from 'hooks';
import { pfizer, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { FormContainer, FormInput, FormInputLabel, HiddenFileUpload } from '../FormComponents';

let fileReader;

if (typeof window !== 'undefined') {
  fileReader = new FileReader();
}

const UserProfile = ({ handleLogout }) => {
  const { selectedEvent, setShowUserAccountDeletedModal } = useContext(LocalContext);
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [avatar, setAvatar] = useState(user.avatarUrl);
  const [formValues, setFormValues] = useState({
    avatarFile: '',
    name: user.name,
    email: user.email
  });
  const [savingChanges, setSavingChanges] = useState({
    name: false,
    email: false,
    avatar: false
  });
  const [notification, setNotification] = useState('');
  const [error, setError] = useState({
    code: '',
    message: ''
  });
  const { windowWidth } = useWindowSize();
  const [generatingUserReport, setGeneratingUserReport] = useState(false);
  const [requestAccountDeletionConfirmation, setRequestAccountDeletionConfirmation] =
    useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const timeToWaitBeforeSavingChanges = 750;

  useEffect(() => {
    fileReader.addEventListener('load', () => {
      const avatarFile = fileReader.result;
      setFormValues((currentValues) => ({
        ...currentValues,
        avatarFile
      }));
    });
  }, []);

  useEffect(() => {
    if (formValues.avatarFile) {
      setSavingChanges((currentState) => ({
        ...currentState,
        avatar: true
      }));

      /* We wait 2.5 seconds, just to buy the backend some time uploading the image and resizing it.
      Othwerise the user might wonder why the avatar has updated instantly on their profile popup,
      but not in their navbar or any comments. */
      setTimeout(() => {
        /* We set the local file in memory as the avatar here first, if the file is still being resized and
        uploaded to the database for persistence. */
        setAvatar(formValues.avatarFile);

        setSavingChanges((currentState) => ({
          ...currentState,
          avatar: false
        }));
      }, 2500);

      firebase
        .uploadAvatarToDatabase(formValues.avatarFile)
        .then(() => firebase?.auth?.currentUser?.reload());
    }
  }, [formValues.avatarFile]);

  useEffect(() => {
    if (user.avatarUrl !== avatar) {
      setAvatar(user.avatarUrl);
    }
  }, [user.avatarUrl]);

  useEffect(() => {
    if (formValues.name !== user.name) {
      setFormValues((currentValues) => ({
        ...currentValues,
        name: user.name
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      name: false
    }));
  }, [user.name]);

  useEffect(() => {
    if (formValues.email !== user.email) {
      setFormValues((currentValues) => ({
        ...currentValues,
        email: user.email
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      email: false
    }));
  }, [user.email]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.name && formValues.name !== user.name) {
        setSavingChanges((currentState) => ({
          ...currentState,
          name: true
        }));
      }

      const changeName = async () => {
        await firebase
          .updateNameInDatabase({ uid: user.uid, name: formValues.name })
          .then(() => setNotification('Name updated'))
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSavingChanges((currentState) => ({
              ...currentState,
              name: false
            }));
          });
      };

      if (formValues.name !== '' && formValues.name !== user.name) {
        changeName();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.name]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.email && formValues.email !== user.email) {
        setSavingChanges((currentState) => ({
          ...currentState,
          email: true
        }));
      }

      const changeEmail = async () => {
        await firebase.auth.currentUser
          .updateEmail(formValues.email)
          .then(() => firebase.updateEmailInDatabase({ uid: user.uid, email: formValues.email }))
          .then(() => setNotification('Email address updated'))
          .catch((err) => {
            console.error(err);
            switch (err.code) {
              case 'auth/requires-recent-login':
                setError({
                  code: err.code,
                  message:
                    'Changing your email address is a sensitive operation and requires recent authentication. Please log out and then log in again before retrying.'
                });
                break;
              default:
                setError({
                  code: err.code,
                  message: err.message
                });
                break;
            }
            setSavingChanges((currentState) => ({
              ...currentState,
              email: false
            }));
          });
      };

      if (formValues.email !== '' && formValues.email !== user.email) {
        changeEmail();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((currentValues) => ({
      ...currentValues,
      [name]: value
    }));
  };

  const handleGenerateUserReport = () => {
    setGeneratingUserReport(true);
    firebase
      .generateUserReport({ email: user.email, selectedEvent })
      .then(({ data }) => {
        setGeneratingUserReport(false);
        return window.open(data, '_blank') || window.location.replace(data);
      })
      .catch(console.error);
  };

  const handleAccountDeletion = async () => {
    setDeletingAccount(true);
    try {
      await firebase?.auth?.currentUser.delete();
      setShowUserAccountDeletedModal(true);
      navigate('/');
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case 'auth/requires-recent-login':
          setError({
            code: err.code,
            message:
              'Deleting your account is a sensitive operation and requires recent authentication. Please log out and then log in again before retrying.'
          });
          break;
        default:
          setError({
            code: err.code,
            message: err.message
          });
          break;
      }
    } finally {
      setDeletingAccount(false);
    }
  };

  return (
    <CustomContainer style={{ paddingBottom: 0 }} colors={selectedEvent?.colors || pfizer}>
      {requestAccountDeletionConfirmation ? (
        <>
          <p
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              lineHeight: '1.75rem',
              marginBottom: '2.25rem',
              textAlign: 'center'
            }}>
            Are you sure you want to delete
            <br />
            your iTransform 2020 account?
          </p>
          <ErrorMessage
            errorMessage={error.message}
            style={{
              color: '#fff',
              margin: '-0.75rem auto 2rem',
              maxWidth: '360px'
            }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: '420px',
              width: '100%',
              margin: '0 auto',
              marginBottom: '2.25rem'
            }}>
            <Button
              onClick={() => {
                if (error.message) {
                  setError({
                    code: '',
                    message: ''
                  });
                }
                setRequestAccountDeletionConfirmation(false);
              }}
              whileHover={{
                scale: 1.025
              }}
              whileTap={{
                scale: 0.975
              }}
              style={{
                backgroundColor: selectedEvent?.colors.tertiary || pfizer.secondary,
                width: '200px'
              }}>
              Go Back
            </Button>
            {error.code === 'auth/requires-recent-login' ? (
              <Button
                savingChanges={savingChanges}
                type="button"
                onClick={() => handleLogout()}
                width="8rem"
                whileHover={{
                  scale: 1.025
                }}
                whileTap={{
                  scale: 0.975
                }}
                style={{
                  backgroundColor: selectedEvent?.colors.tertiary || pfizer.secondary,
                  width: '200px'
                }}>
                Logout
              </Button>
            ) : (
              <Button
                onClick={handleAccountDeletion}
                whileHover={{
                  scale: 1.025
                }}
                whileTap={{
                  scale: 0.975
                }}
                style={{
                  backgroundColor: selectedEvent?.colors.tertiary || pfizer.secondary,
                  width: '200px'
                }}>
                {deletingAccount ? (
                  <LoadingSpinner style={{ color: '#fff', width: '2.5rem' }} />
                ) : (
                  'Confirm Deletion'
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <ProfileImageWrapper colors={selectedEvent?.colors || pfizer}>
            {savingChanges.avatar ? (
              <AnimatePresence>
                {savingChanges.avatar && (
                  <LoadingSpinner
                    style={{
                      width: '6rem',
                      color: selectedEvent?.colors.secondary || pfizer.primary
                    }}
                  />
                )}
              </AnimatePresence>
            ) : (
              <AnimatePresence>
                {!savingChanges.avatar &&
                  (avatar ? (
                    <ProfileImage
                      src={avatar}
                      alt={user.name}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    />
                  ) : (
                    <DefaultAvatar
                      width="70"
                      fill={selectedEvent?.colors.primary || pfizer.primary}
                    />
                  ))}
              </AnimatePresence>
            )}
          </ProfileImageWrapper>
          {savingChanges.avatar ? (
            <AnimatePresence>
              {savingChanges.avatar && (
                <SavingNewAvatar
                  saving={savingChanges.avatar}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  Uploading
                </SavingNewAvatar>
              )}
            </AnimatePresence>
          ) : (
            <AnimatePresence>
              {!savingChanges.avatar && (
                <>
                  <SetOrChangeAvatarButton
                    htmlFor="avatarFile"
                    whileHover={{
                      backgroundColor: selectedEvent?.colors.tertiary || pfizer.secondary,
                      borderColor: selectedEvent?.colors.tertiary || pfizer.secondary
                    }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    {`${avatar ? 'Change' : 'Set'} Profile Picture`}
                  </SetOrChangeAvatarButton>
                  <HiddenFileUpload
                    type="file"
                    name="avatarFile"
                    id="avatarFile"
                    onChange={(e) =>
                      e.target.files.length && fileReader.readAsDataURL(e.target.files[0])
                    }
                  />
                </>
              )}
            </AnimatePresence>
          )}
          <FormInputLabel htmlFor="name" style={{ fontWeight: 400 }}>
            Full Name
          </FormInputLabel>
          <div style={{ position: 'relative' }}>
            <FormInput
              id="name"
              name="name"
              type="text"
              value={formValues.name}
              onChange={handleInputChange}
              style={{
                marginBottom: '1.75rem'
              }}
              autoComplete="off"
            />
            {savingChanges.name && (
              <LoadingSpinner
                style={{
                  position: 'absolute',
                  color: '#fff',
                  top: '0.45rem',
                  left: 'calc(100% - 2.75rem)',
                  width: '1.9rem'
                }}
              />
            )}
          </div>
          <FormInputLabel htmlFor="email" style={{ fontWeight: 400 }}>
            Email
          </FormInputLabel>
          <div style={{ position: 'relative' }}>
            <FormInput
              id="email"
              name="email"
              type="email"
              readOnly
              value={formValues.email}
              // onChange={handleInputChange}
              style={{
                marginBottom: '2rem',
                ':focus': {
                  cursor: 'default',
                  pointerEvents: 'none',
                  padding: '0 1.143em',
                  border: '1px solid #fff'
                }
              }}
              autoComplete="off"
            />
            {savingChanges.email && (
              <LoadingSpinner
                style={{
                  position: 'absolute',
                  color: selectedEvent?.colors.secondary || pfizer.secondary,
                  top: '0.75rem',
                  left: 'calc(100% - 2.75rem)',
                  width: '2.25rem'
                }}
              />
            )}
          </div>
          <Notification
            notification={notification}
            callback={() => setNotification('')}
            timeout={3000}
          />
          <ErrorMessage
            errorMessage={error.message}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div style={{ alignSelf: 'center', marginTop: '1.0625rem', marginBottom: '1.313rem' }}>
            <Button
              savingChanges={savingChanges}
              type="button"
              onClick={() => handleLogout()}
              width="8rem"
              whileHover={{
                scale: 1.05
              }}
              whileTap={{
                scale: 0.975
              }}
              style={{
                backgroundColor: selectedEvent?.colors.tertiary || pfizer.secondary
              }}>
              Logout
            </Button>
          </div>
          {!loading && user.isAdmin && (
            <div
              style={{
                display: 'flex',
                height: '1.5rem',
                justifyContent: 'center',
                fontSize: '0.825rem',
                marginBottom: '10px',
                position: 'relative',
                width: '100%'
              }}>
              <AnimatePresence>
                {generatingUserReport && (
                  <LoadingSpinner
                    style={{
                      color: '#fff',
                      position: 'absolute',
                      top: '-0.5rem',
                      width: '2.25rem'
                    }}
                  />
                )}
              </AnimatePresence>
              <AnimatePresence>
                {!generatingUserReport && (
                  <GenerateReport
                    type="button"
                    whileHover={{
                      scale: 1.05
                    }}
                    whileTap={{
                      scale: 0.95
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                      fontSize: '0.825rem',
                      marginBottom: '0'
                    }}
                    onClick={() => handleGenerateUserReport()}>
                    Generate User Report
                  </GenerateReport>
                )}
              </AnimatePresence>
            </div>
          )}
          <DeleteAccount
            type="button"
            whileHover={{
              scale: 1.05
            }}
            whileTap={{
              scale: 0.95
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              fontSize: '0.825rem',
              marginBottom: '1.75em'
            }}
            onClick={() => setRequestAccountDeletionConfirmation(true)}>
            Delete Account
          </DeleteAccount>
        </>
      )}
    </CustomContainer>
  );
};

const ProfileImageWrapper = styled.div`
  align-items: center;
  align-self: center;
  background: #c4c4c4;
  border: 0.2rem solid ${({ colors }) => colors.primary};
  border-radius: 50%;
  display: flex;
  height: 10rem;
  justify-content: center;
  margin-bottom: 1.75rem;
  margin-top: -7.5rem;
  overflow: hidden;
  width: 10rem;
`;

const ProfileImage = styled(motion.img)`
  background: transparent;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const SavingNewAvatar = styled(motion.p)`
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0 auto 2rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${({ saving }) =>
    saving &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.175rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 #fff;
        }
      }
    `}
`;

const SetOrChangeAvatarButton = styled(motion.label)`
  align-items: center;
  align-self: center;
  border: 1px solid #fff;
  border-radius: 3rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-bottom: 2rem;
  max-width: 17rem;
  text-transform: uppercase;
  width: 100%;
`;

const CustomContainer = styled(FormContainer)`
  ${({ style }) => style}
  background-color: ${({ colors }) => colors.primary};
  margin-bottom: 1.25rem;
  margin-top: 4rem;
  max-width: 100%;
`;

const GenerateReport = styled(motion.button)`
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  position: absolute;
  text-decoration: underline;
  top: 0;
`;

const DeleteAccount = styled(motion.button)`
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  text-decoration: underline;
`;

export default UserProfile;
