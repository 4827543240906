import React from 'react';
import styled, { css } from 'styled-components';
import { Markup } from 'interweave';
import { Button } from 'components';
import { useWindowSize } from 'hooks';
import { hexToRGB } from 'utils';
import { Plus } from 'assets/svgs';

const AgendaSingleDay = ({ agenda, colors, did, eid, date, tabTitle }) => {
  const { windowWidth } = useWindowSize();
  return (
    <Wrapper id="agenda">
      <Agenda>Agenda</Agenda>
      <Day colors={colors}>{`Day ${did}${eid !== '1' ? ` - ${tabTitle.split(' ')[0]}` : ''}`}</Day>
      <Date>{date}</Date>
      <Table>
        <thead>
          <tr>
            {windowWidth < 768 ? (
              <th>Time</th>
            ) : (
              <>
                {/* <th>JPN Time:</th> */}
                <th>CET:</th>
                <th>NY/EST:</th>
              </>
            )}
            <th>Livestream:</th>
            <th>Speakers:</th>
            <th>Duration:</th>
          </tr>
        </thead>
        <tbody>
          {agenda.map((timeslot, i) => (
            <BodyTrackRow
              colors={colors}
              franchiseColor={timeslot.franchiseColor}
              inset={timeslot.inset}
              key={i}>
              {windowWidth < 768 ? (
                timeslot.time ? (
                  <td>
                    {/* JPN: {timeslot.time.jpn}
                    <br /> */}
                    CET: {timeslot.time.cet}
                    <br />
                    NY/EST: {timeslot.time.ny}
                  </td>
                ) : (
                  <td />
                )
              ) : timeslot.time ? (
                <>
                  {/* <td>{timeslot.time.jpn}</td> */}
                  <td>{timeslot.time.cet}</td>
                  <td>{timeslot.time.ny}</td>
                </>
              ) : (
                <>
                  {/* <td /> */}
                  <td />
                  <td />
                </>
              )}
              <td>
                <Markup content={timeslot.livestream} noWrap />
                {timeslot.calendarLink && (
                  <a
                    href={timeslot.calendarLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: 'block', margin: '1.438em 0 0.5em' }}>
                    <Button
                      style={{
                        backgroundColor: colors.secondary,
                        fontSize: windowWidth >= 768 ? '0.75rem' : '0.625rem',
                        height: windowWidth >= 450 ? '2.35em' : '3.25em',
                        lineHeight: '1.362em',
                        padding: '1em',
                        maxWidth: '14.5em',
                        width: '100%'
                      }}
                      whileHover={{
                        scale: 1.0225
                      }}
                      whileTap={{
                        scale: 0.975
                      }}>
                      {windowWidth >= 768 && (
                        <Plus width="0.75rem" style={{ marginRight: '0.75em' }} />
                      )}
                      Add To Calendar
                    </Button>
                  </a>
                )}
              </td>
              <td style={{ paddingLeft: 0 }}>
                <Markup content={timeslot.speakers} noWrap />
              </td>
              <td style={{ paddingLeft: 0 }}>{timeslot.duration}</td>
            </BodyTrackRow>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 5.125rem auto;
  max-width: 72.875rem;
  padding: 0 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 75rem) {
    padding: 0;
  }
`;

const Table = styled.table`
  color: #3c3c3c;
  width: 100%;

  &,
  th,
  td {
    border-collapse: collapse;
  }

  thead tr th {
    font-size: 0.75rem;
    font-weight: 400;
    left: -1.333em;
    line-height: 2.667em;
    padding-right: 1.333em;
    position: relative;
    text-align: left;

    &:nth-of-type(1) {
      left: 0;
      padding-right: 2.666em;
      white-space: nowrap;
    }
  }
`;

const BodyTrackRow = styled.tr`
  background-color: ${({ franchiseColor }) =>
    franchiseColor ? hexToRGB({ color: franchiseColor, alpha: 0.5 }) : 'transparent'};
  color: #3c3c3c;
  font-size: 1rem;
  left: -1em;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  :hover {
    ${({ franchiseColor }) =>
      !franchiseColor &&
      css`
        background-color: #e6f4ff;
        color: ${({ colors }) => colors.primary};
      `}
  }

  td {
    font-size: 0.85rem;
    line-height: 1.5em;
    padding: ${({ inset }) => (inset ? '0.1em 1em 0.1em 1.125em' : '0.75em 1em 0.75em 0')};
    vertical-align: top;

    @media screen and (min-width: 48rem) {
      font-size: 1rem;
    }

    &:nth-of-type(1) {
      font-size: 0.75rem;
      padding: 0.75rem 1rem;
      white-space: nowrap;

      @media screen and (min-width: 48rem) {
        font-size: 1rem;
        white-space: unset;
      }
    }
  }
`;

const Agenda = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 37px;
`;

const Day = styled.span`
  align-items: center;
  background-color: ${({ colors }) => colors.secondary};
  border: 0.063em solid transparent;
  border-radius: 1.875em;
  color: #fff;
  display: flex;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 400;
  height: 3em;
  justify-content: center;
  padding: 0 1.875em;
`;

const Date = styled.p`
  color: #3c3c3c;
  font-size: 1.25rem;
  margin: 2.65em 0 1.25em;
`;

export default AgendaSingleDay;
