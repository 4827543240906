import * as React from 'react';

function SvgTarget5(props) {
  return (
    <svg viewBox="0 0 123 123" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.788 6.7a.861.861 0 01.353-.327A61.778 61.778 0 0165.388.203a.86.86 0 01.8.912.86.86 0 01-.912.791A60.084 60.084 0 0034.899 7.93a.86.86 0 01-1.145-.396.861.861 0 01.034-.835zm44.156-4.2a.86.86 0 00-.423.353 1.043 1.043 0 00-.069.224.86.86 0 00.577 1.067 59.456 59.456 0 0114.216 6.264 59.95 59.95 0 0111.79 9.337.857.857 0 001.213-1.213A61.656 61.656 0 0093.123 8.92a62.01 62.01 0 00-14.63-6.446.86.86 0 00-.55.025zM49.27 12.605A50.418 50.418 0 0018.196 35.4a50.419 50.419 0 0016.978 69.17 50.422 50.422 0 0069.17-16.979 50.419 50.419 0 00-16.978-69.17 50.42 50.42 0 00-38.096-5.814zm59.223 37.29a48.697 48.697 0 01-5.612 36.8v.034a48.704 48.704 0 01-66.82 16.376 48.717 48.717 0 01-16.402-66.821A48.698 48.698 0 0186.47 19.883a48.695 48.695 0 0122.022 30.012zm-86.562-35.65A61.434 61.434 0 008.696 29.65a61.606 61.606 0 00-7.35 18.08.86.86 0 00.646 1.024.86.86 0 001.024-.637 59.832 59.832 0 017.151-17.58 59.623 59.623 0 0112.856-14.974.861.861 0 00.112-1.205.86.86 0 00-1.204-.112zM.486 69.252a.86.86 0 01.62-.413.86.86 0 01.963.74 59.85 59.85 0 005.413 17.942.86.86 0 01-.404 1.145.86.86 0 01-1.145-.404 61.762 61.762 0 01-5.567-18.45.861.861 0 01.12-.56zm13.218 29.835a.86.86 0 00-.12 1.205 61.214 61.214 0 0015.842 13.768 60.856 60.856 0 0048.25 6.661.861.861 0 00.603-1.05.859.859 0 00-1.059-.594 59.203 59.203 0 01-46.908-6.48 59.8 59.8 0 01-15.404-13.38.86.86 0 00-1.204-.13zm76.493 14.827a.866.866 0 01.318-.31 59.75 59.75 0 0021.858-21.152 59.311 59.311 0 008.158-23.234.863.863 0 01.964-.74.86.86 0 01.74.963 61.032 61.032 0 01-8.399 23.915 61.458 61.458 0 01-22.486 21.763.859.859 0 01-1.17-.327.86.86 0 01.017-.878zm25.807-78.472a.85.85 0 00-.498.73.858.858 0 00.077.405 60.434 60.434 0 013.803 11.067.862.862 0 001.033.628.86.86 0 00.637-1.032 61.965 61.965 0 00-3.915-11.377.863.863 0 00-1.137-.422z"
        fill="url(#target-5_svg__paint0_linear_224_11)"
      />
      <defs>
        <linearGradient
          id="target-5_svg__paint0_linear_224_11"
          x1={118.292}
          y1={35.365}
          x2={26.01}
          y2={112.15}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD00CE" />
          <stop offset={0.495} stopColor="#ED2524" />
          <stop offset={0.979} stopColor="#FFBE38" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTarget5;
